import moment from "moment";
const buildWeeklyCalendar = (calComp) => {

    const weekStart = calComp.clone().startOf('week');

        var days = [];
        for (let i = 0; i <= 6; i++) {
    
            days.push(moment(weekStart).add(i, 'days').clone());
    
        };
        return days
    }

export default buildWeeklyCalendar;