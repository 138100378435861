export const homeLanguages = [
   {
    value: 'Afrikaans',
    label: 'Afrikaans'
   },
   {
    value: 'English',
    label: 'English'
   },
   {
    value: 'isiNdebele',
    label: 'isiNdebele'
   },
   {
    value: 'isiXhosa',
    label: 'isiXhosa'
   },
   {
    value: 'isiZulu',
    label: 'isiZulu'
   },
   {
    value: 'Sesotho',
    label: 'Sesotho'
   },
   {
    value: 'Sesotho sa Leboa (Sepedi)',
    label: 'Sesotho sa Leboa (Sepedi)'
   },
   {
    value: 'Setswana',
    label: 'Setswana',
   },
   {
    value: 'siSwati',
    label: 'siSwati'
   },
   {
    value: 'Tshivenda',
    label: 'Tshivenda'
   },
   {
    value: 'Xitsonga',
    label: 'Xitsonga'
   },
];

export default homeLanguages