import { toast } from "react-toastify";

const patientsReducer = (state=[],action) => {
    switch(action.type) {
        case 'GET_PATIENTS':
            return action.patients.data
        case 'SEARCH_PATIENTS':
            return action.patient.data
        case 'ADD_PATIENT':

            toast("Patient Added", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });

        return [action.data.patientData, ...state]
        default:
            return state
    }
}

export default patientsReducer;