import React, { useEffect, useState } from 'react';

import buildWeeklyCalendar from './buildWeeklyCalendar';
import buildMonthlyCalendar from './buildMonthlyCalendar';
import buildYearlyCalendar from './buildYearlyCalendar';

import main from '../../App.module.css';

import { Box, Button, InputAdornment, Modal, Stack, TextField, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddTaskIcon from '@mui/icons-material/AddTask';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import classNames from 'classnames';
import styles from '../assets/css/Calendar.module.css';

import moment from 'moment';
import { isFirstDayOfMonth } from 'date-fns/esm';
import { NextWeek } from '@mui/icons-material';

  // const modalStyle = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 800,
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  //   borderRadius: '25px',
  //   boxShadow: 24,
  //   p: 4,
  // };

const Calendar = () => {

  const [weeklyCalendar ,setWeeklyCalendar] = useState([]);
  const [monthlyCalendar ,setMonthlyCalendar] = useState([]);
  const [yearlyCalendar ,setYearlyCalendar] = useState([]);

  // console.log(weeklyCalendar)

  const [calComp, setCalComp] = useState(moment());

  const weekdays = moment.weekdays();
  const months = moment.months();

  const weekDaySorted = () => {
    let arrDay = [...weekdays];
    arrDay.push(arrDay.shift());
    return arrDay
  };

  let weekdaysName = weekDaySorted().map(day => {
    return (
      <th key={day} className={styles.weekday}>
        {day}
      </th>
    )
  })

  const monthsSorted = () => {
    let arrDay = [...months];
    arrDay.push(arrDay.shift());
    return arrDay
  };

  let monthsName = monthsSorted().map(month => {
    return (
      <th key={month} className={styles.month}>
        {month}
      </th>
    )
  })

  // console.log(weekDaySorted())

  useEffect(()=> {
    setWeeklyCalendar(buildWeeklyCalendar(calComp));
    setMonthlyCalendar(buildMonthlyCalendar(calComp));
    // setYearlyCalendar(buildYearlyCalendar(calComp));
  }, [calComp])

  function isSelected(day) {
    return calComp.isSame(day, "day")
  }

  function beforeToday(day) {
    return calComp.isBefore(new Date(), "day")
  }

  function isToday(day) {
    return calComp.isSame(new Date(), "day")
  }

  function currentWeekNr() {
    return calComp.isoWeek()
  }

  function currentMonthName() {
    return calComp.format("MMMM")
  }

  function currentYear() {
    return calComp.format("YYYY")
  }

  function prevWeek() {
    return calComp.clone().subtract(1, "week")
  }

  function nextWeek() {
    return calComp.clone().add(1, "week")
  }

  function prevMonth() {
    return calComp.clone().subtract(1, "month")
  }

  function nextMonth() {
    return calComp.clone().add(1, "month")
  }

  function prevYear() {
    return calComp.clone().subtract(1, "year")
  }

  function nextYear() {
    return calComp.clone().add(1, "year")
  }

  const firstDayOfMonth = () => {
    let firstDay = calComp.clone().startOf('M'); 
   return firstDay;
};

const [calValue, setCalValue] = useState(1);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  };
}

const handleCalChange = (event, newValue) => {
  setCalValue(newValue);
}

const getHoursOfDay = () => {
    const hours = []
    for (let i = 6; i <= 19; i++) {
      let hour = i;
      hours.push(hour)
  }
  return hours
}

const hoursOfDay = getHoursOfDay()
// console.log(calComp.format('day'))

    // if(auth._id) return <Navigate to="/" />
    
    return (
      <>
              <header
                className={classNames(styles.content_box1, styles.content_box1_layout)}>
                <h1 className={classNames(styles.hero_title, styles.hero_title_layout)}>
                  {'Calendar'}
                </h1>
                <h1 className={classNames(styles.big_title, styles.big_title_layout)}>
                {new Date().toLocaleString("en-ZA", { day : '2-digit', month: "long" } )} {new Date().getFullYear()}
                </h1>
              </header>
              <main id='main' className={classNames(main.main)}>
                <div id='FlexContainer' className={ calValue === 0 ? classNames(styles.flex, styles.flex_layout_week) : classNames(styles.flex, styles.flex_layout_month)}>
                  <div className={classNames(styles.actions_box, styles.actions_box_layout)}>
                    <Stack direction="row" spacing={2} style={{marginRight: '20px'}}>

                        <TextField
                            id="search-bar"
                            // onChange={event => setQuery(event.target.value)}
                            // value={capitalize(searchQuery)}
                            variant="outlined"
                            placeholder="Search Calendar..."
                            size="small"
                            style={{ marginLeft: '70px', width: '300px'}}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon style={{ fill: "blue" }} />
                                </InputAdornment>
                              ),
                            }}
                        />
                        <Button variant="contained" endIcon={<NoteAddIcon />} style={{ backgroundColor: '#000', color: '#fff'}}>
                            Add Note
                        </Button>
                        <Button variant="contained" startIcon={<FilterAltIcon />} style={{ backgroundColor: '#fff', color: '#122795'}}>
                            Filter Calendar
                        </Button>
                        <Button variant="outlined" endIcon={<AddTaskIcon />} style={{ backgroundColor: '#122795', color: '#fff'}}>
                            Add Task
                        </Button>
                    </Stack>

                    {/* <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="booking-modal-title"
                        aria-describedby="booking-modal-description"
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Box sx={modalStyle}>
                          <Typography id="booking-modal-title" variant="h6" component="h2">
                            Schedule Appointment
                          </Typography>
                          <Typography id="booking-modal-description" sx={{ mt: 2 }}>
                            Pleas provide Booking Details
                          </Typography>
                        </Box>
                    </Modal> */}
                  </div>
        
                  <div className={calValue === 0 ? classNames(styles.box_calendar, styles.box_layout_overview_calendarWeek) : classNames(styles.box_calendar, styles.box_layout_overview_calendarMonth)} >
                    <div className={classNames(styles.box_calendar_overview, styles.box_layout_calendar_overview)} >

                    <Tabs
                      orientation="horizontal"
                      // variant="scrollable"
                      value={calValue}
                      onChange={handleCalChange}
                      aria-label="Calendar Tabs"
                      className={classNames(styles.calSwitch)}
                    >
                      <Tab label="Week" {...a11yProps(0)} />
                      <Tab label="Month" {...a11yProps(1)} />
                      <Tab label="Year" {...a11yProps(2)} />
                    </Tabs>
                    <TabPanel value={calValue} index={0}>
                      <div className={classNames(styles.box_calendar_nav, styles.box_layout_calendar_nav)} >
                        <IconButton onClick={()=> setCalComp(prevWeek())}><ArrowBackIosNewIcon />prev </IconButton>{`${currentMonthName()} Week ${currentWeekNr()}`}<IconButton onClick={()=> setCalComp(nextWeek())}>next <ArrowForwardIosIcon /></IconButton>
                        {/* <IconButton onClick={()=> setCalComp(prevYear())}><ArrowBackIosNewIcon /></IconButton>{currentYear()}<IconButton onClick={()=> setCalComp(nextYear())}><ArrowForwardIosIcon /></IconButton> */}
                      </div>
                      <table className={calValue === 0 ? classNames(styles.calendar_tableWeek, styles.layout_calendar_table) : classNames(styles.calendar_table, styles.layout_calendar_table)}>
                        <thead className={classNames(styles.calendar_table_head)}>
                          <tr>
                            {weeklyCalendar.map(day => <th key={day} className={styles.weekday}>
                                {day.format('dddd')}
                              </th>
                              )
                            }
                          </tr>
                          <tr>
                            {
                            weeklyCalendar.map(day => <td key={day} className={styles.weekday}>
                                {<span className={calComp.isSame(day, "day") ? classNames(day.format('dddd') === 'Saturday' || day.format('dddd') === 'Sunday' ? styles.weeklyWeekendNrActive : styles.weeklyWeekdayNrActive) : classNames(day.format('dddd') === 'Saturday' || day.format('dddd') === 'Sunday' ? styles.weeklyWeekendNr : styles.weeklyWeekdayNr)}>{day.format("Do")}</span>}
                              </td>
                              )
                            }
                        </tr>
                        </thead>
                        <tbody className={classNames(styles.calendar_table_bodyWeek)}>
                        {
                          hoursOfDay.map(hour => <tr className={hour % 2 === 0 ? classNames(styles.evenHour) : classNames(styles.oddHour)}key={hour}>
                            {
                              hour < 10 ? (<td key={hour} className={classNames(styles.dayHour)}>{`0${hour}:00`}</td>) : (<td key={hour} className={classNames(styles.dayHour)}>{`${hour}:00`}</td>)
                            }
                            {
                              weeklyCalendar.map(day => <td key={day} className={classNames(styles.weeklyHour)}></td>)
                            }
                            </tr>
                          )
                        }
                        </tbody>
                      </table>
                    </TabPanel>
                    <TabPanel value={calValue} index={1}>
                      <div className={classNames(styles.box_calendar_nav, styles.box_layout_calendar_nav)} >
                        <IconButton onClick={()=> setCalComp(prevMonth())}><ArrowBackIosNewIcon /></IconButton>{currentMonthName()}<IconButton onClick={()=> setCalComp(nextMonth())}><ArrowForwardIosIcon /></IconButton>
                        <IconButton onClick={()=> setCalComp(prevYear())}><ArrowBackIosNewIcon /></IconButton>{currentYear()}<IconButton onClick={()=> setCalComp(nextYear())}><ArrowForwardIosIcon /></IconButton>
                      </div>
                      <table className={classNames(styles.calendar_table, styles.layout_calendar_table)}>
                        <thead className={classNames(styles.calendar_table_head)}>
                          <tr>
                            {weekdaysName}
                          </tr>
                        </thead>
                        <tbody className={classNames(styles.calendar_table_bodyMonth)} style={{ marginTop: '20px' }}>
                            {
                              monthlyCalendar.map(week => <tr key={week} className={classNames(styles.weekdayRow)}>
                                {
                                  
                                  week.map(day => <td key={day} onClick={()=> setCalComp(day)} className={classNames(calComp.isBefore(firstDayOfMonth(), "day")  ? styles.weekdayBFSBox : styles.weekdayBox)}>
                                        {/* {console.log(day.format('D'))} */}
                                        <div className={classNames(styles.weekdayBoxData)}>
                                        <span className={calComp.isSame(day, "day") ? classNames(day.format('dddd') === 'Saturday' || day.format('dddd') === 'Sunday' ? styles.weekendNrActive : styles.weekdayNrActive) : classNames(day.format('dddd') === 'Saturday' || day.format('dddd') === 'Sunday' ? styles.weekendNr : styles.weekdayNr)}>{day.format("D")}</span>
                                        </div>
                                  </td>)
                                }
                              </tr>)
                            }
                        </tbody>
                      </table>
                    </TabPanel>
                    <TabPanel value={calValue} index={2}>
                      Item Three
                    </TabPanel>
                    </div>
                  </div>
                </div>      
              </main>
      </>
      );
}
 
export default Calendar;
