export const medicalSchemes = [
   {
    value: 'None',
    label: 'None'
   },
   {
      value: 'Aeci Medical Aid Society',
      label: 'Aeci Medical Aid Society'
     },
   {
    value: 'Alliance-Midmed Medical Scheme',
    label: 'Alliance-Midmed Medical Scheme'
   },
   {
    value: 'Anglo Medical Scheme',
    label: 'Anglo Medical Scheme'
   },
   {
    value: 'Anglovaal Group Medical Scheme',
    label: 'Anglovaal Group Medical Scheme'
   },
   {
    value: 'Bankmed',
    label: 'Bankmed'
   },
   {
    value: 'Barloworld Medical Scheme',
    label: 'Barloworld Medical Scheme'
   },
   {
    value: 'Bestmed Medical Scheme',
    label: 'Bestmed Medical Scheme'
   },
   {
    value: 'Bmw Employees Medical Aid Society',
    label: 'Bmw Employees Medical Aid Society'
   },
   {
    value: 'Bonitas Medical Fund',
    label: 'Bonitas Medical Fund'
   },
   {
    value: 'Bp Medical Aid Society',
    label: 'Bp Medical Aid Society'
   },
   {
    value: 'Building & Construction Industry Medical Aid Fund',
    label: 'Building & Construction Industry Medical Aid Fund'
   },
   {
    value: 'Cape Medical Plan',
    label: 'Cape Medical Plan'
   },
   {
    value: 'Chartered Accountants (Sa) Medical Aid Fund (Camaf)',
    label: 'Chartered Accountants (Sa) Medical Aid Fund (Camaf)'
   },
   {
    value: 'Compcare Wellness Medical Scheme',
    label: 'Compcare Wellness Medical Scheme'
   },
   {
    value: 'De Beers Benefit Society',
    label: 'De Beers Benefit Society'
   },
   {
    value: 'Discovery Health Medical Scheme',
    label: 'Discovery Health Medical Scheme'
   },
   {
    value: 'Engen Medical Benefit Fund',
    label: 'Engen Medical Benefit Fund'
   },
   {
    value: 'Fedhealth Medical Scheme',
    label: 'Fedhealth Medical Scheme'
   },
   {
    value: 'Fishing Industry Medical Scheme (Fish-Med)',
    label: 'Fishing Industry Medical Scheme (Fish-Med)'
   },
   {
    value: 'Foodmed Medical Scheme',
    label: 'Foodmed Medical Scheme'
   },
   {
    value: 'Genesis Medical Scheme',
    label: 'Genesis Medical Scheme'
   },
   {
    value: 'Glencore Medical Scheme',
    label: 'Glencore Medical Scheme'
   },
   {
    value: 'Golden Arrows Employees\' Medical Benefit Fund',
    label: 'Golden Arrows Employees\' Medical Benefit Fund'
   },
   {
    value: 'Government Employees Medical Scheme (Gems)',
    label: 'Government Employees Medical Scheme (Gems)'
   },
   {
    value: 'Health Squared Medical Scheme',
    label: 'Health Squared Medical Scheme'
   },
   {
    value: 'Horizon Medical Scheme',
    label: 'Horizon Medical Scheme'
   },
   {
    value: 'Impala Medical Plan',
    label: 'Impala Medical Plan'
   },
   {
    value: 'Imperial And Motus Medical Aid',
    label: 'Imperial And Motus Medical Aid'
   },
   {
    value: 'Keyhealth',
    label: 'Keyhealth'
   },
   {
    value: 'La-Health Medical Scheme',
    label: 'La-Health Medical Scheme'
   },
   {
    value: 'Libcare Medical Scheme',
    label: 'Libcare Medical Scheme'
   },
   {
    value: 'Lonmin Medical Scheme',
    label: 'Lonmin Medical Scheme'
   },
   {
    value: 'Makoti Medical Scheme',
    label: 'Makoti Medical Scheme'
   },
   {
    value: 'Malcor Medical Scheme',
    label: 'Malcor Medical Scheme'
   },
   {
    value: 'Massmart Health Plan',
    label: 'Massmart Health Plan'
   },
   {
    value: 'Mbmed Medical Aid Fund',
    label: 'Mbmed Medical Aid Fund'
   },
   {
    value: 'Medihelp',
    label: 'Medihelp'
   },
   {
    value: 'Medimed Medical Scheme',
    label: 'Medimed Medical Scheme'
   },
   {
    value: 'Medipos Medical Scheme',
    label: 'Medipos Medical Scheme'
   },
   {
    value: 'Medshield Medical Scheme',
    label: 'Medshield Medical Scheme'
   },
   {
    value: 'Momentum Medical Scheme',
    label: 'Momentum Medical Scheme'
   },
   {
    value: 'Motohealth Care',
    label: 'Motohealth Care'
   },
   {
    value: 'Multichoice Medical Aid Scheme',
    label: 'Multichoice Medical Aid Scheme'
   },
   {
    value: 'Netcare Medical Scheme',
    label: 'Netcare Medical Scheme'
   },
   {
    value: 'Old Mutual Staff Medical Aid Fund',
    label: 'Old Mutual Staff Medical Aid Fund'
   },
   {
    value: 'Parmed Medical Aid Scheme',
    label: 'Parmed Medical Aid Scheme'
   },
   {
    value: 'Pg Group Medical Scheme',
    label: 'Pg Group Medical Scheme'
   },
   {
    value: 'Pick N Pay Medical Scheme',
    label: 'Pick N Pay Medical Scheme'
   },
   {
    value: 'Platinum Health',
    label: 'Platinum Health'
   },
   {
    value: 'Profmed',
    label: 'Profmed'
   },
   {
    value: 'Rand Water Medical Scheme',
    label: 'Rand Water Medical Scheme'
   },
   {
    value: 'Remedi Medical Aid Scheme',
    label: 'Remedi Medical Aid Scheme'
   },
   {
    value: 'Retail Medical Scheme',
    label: 'Retail Medical Scheme'
   },
   {
    value: 'Rhodes University Medical Scheme',
    label: 'Rhodes University Medical Scheme'
   },
   {
    value: 'Sabc Medical Aid Scheme',
    label: 'Sabc Medical Aid Scheme'
   },
   {
    value: 'Samwumed',
    label: 'Samwumed'
   },
   {
    value: 'Sasolmed',
    label: 'Sasolmed'
   },
   {
    value: 'Sedmed',
    label: 'Sedmed'
   },
   {
    value: 'Sisonke Health Medical Scheme',
    label: 'Sisonke Health Medical Scheme'
   },
   {
    value: 'Sizwe Hosmed Medical Fund',
    label: 'Sizwe Hosmed Medical Fund'
   },
   {
    value: 'South African Breweries Medical Scheme',
    label: 'South African Breweries Medical Scheme'
   },
   {
    value: 'South African Police Service Medical Scheme (Polmed)',
    label: 'South African Police Service Medical Scheme (Polmed)'
   },
   {
    value: 'Suremed Health',
    label: 'Suremed Health'
   },
   {
    value: 'Tfg Medical Aid Scheme',
    label: 'Tfg Medical Aid Scheme'
   },
   {
    value: 'Thebemed',
    label: 'Thebemed'
   },
   {
    value: 'Tiger Brands Medical Scheme',
    label: 'Tiger Brands Medical Scheme'
   },
   {
    value: 'Transmed Medical Fund',
    label: 'Transmed Medical Fund'
   },
   {
    value: 'Tsogo Sun Group Medical Scheme',
    label: 'Tsogo Sun Group Medical Scheme'
   },
   {
    value: 'Umvuzo Health Medical Scheme',
    label: 'Umvuzo Health Medical Scheme'
   },
   {
    value: 'University Of Kwa-Zulu Natal Medical Scheme',
    label: 'University Of Kwa-Zulu Natal Medical Scheme'
   },
   {
    value: 'Witbank Coalfields Medical Aid Scheme',
    label: 'Witbank Coalfields Medical Aid Scheme'
   },
   {
    value: 'Wooltru Healthcare Fund',
    label: 'Wooltru Healthcare Fund'
   },
];

export default medicalSchemes