import React, { useState } from 'react';
import moment from 'moment';

import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { updatePatient } from '../../store/actions/patientActions';

import { makeStyles } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Autocomplete, Box } from '@mui/material';
import styles from '../assets/css/AddPatient.module.css';

import medicalSchemes from './medicalSchemes'
import homeLanguages from './languages'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const genders = [
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
];

const citizenships = [
  {
    value: '0',
    label: 'SA Citizen',
  },
  {
    value: '1',
    label: 'Permanent Resident',
  },
];

const useStyles = makeStyles({
    formStyle: {
        padding: '30px',
        maxWidth: '1500px',
        width: '100%'
    },
    spacing: {
        marginTop: '20px'
    },
    muiCustom: {
      borderRadius: '30px',
      margin: '25px',
      padding: '50px',
      boxShadow: '0px 0px 12px -3px #000000'
    }
});

// import moment from 'moment';

const PatientUpdate = ({patient}) => {

  const navigate = useNavigate();

  const classes = useStyles();

  const [gender, setGender] = useState('');
  const [patientCitizenshipClass, setPatientCitizenshipClass] = useState('');

  const handleChange = (event) => {
      setGender(event.target.value);
      setPatientCitizenshipClass(event.target.value);
  };

  const [values, setValues] = useState({
      weight: '',
      height: '',
    });

    // const [miscValues, setMiscValues] = useState({
    //   weight: '',
    //   height: '',
    // });

  const handleWHChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  // const auth = useSelector(state=> state.auth)
  const dispatch = useDispatch();
  const [patientData, setPatientInfo] = useState({
    patientName: patient.patientName,
    patientSurname: patient.patientSurname,
    patientGender: patient.patientGender,
    patientCitizenship: patient.patientCitizenship,
    patientId: patient.patientId,
    patientBirthDate: patient.patientBirthDate,
    patientHomeLanguage: patient.patientHomeLanguage,
    patientAge: patient.patientAge,
    patientWeight: patient.patientWeight,
    patientHeight: patient.patientHeight,
    patientBMI: patient.patientBMI,
    knownChronicIllnesses: "",
    patientMedicalScheme: patient.patientName
  })
  const handleSubmit = (e)=> {
      e.preventDefault()
      dispatch(updatePatient(patientData, patient._id))
      setPatientInfo({
        patientName: "",
        patientSurname: "",
        patientGender: "",
        patientCitizenship: "",
        patientId: "",
        patientBirthDate: "",
        patientHomeLanguage: "",
        patientAge: "",
        patientWeight: "",
        patientHeight: "",
        patientBMI: "",
        knownChronicIllnesses: "",
        patientMedicalScheme: ""
      })
  }

  // const validateId = (patientId) => {

  //     let checksumValidation = "";
  //     const checksum = patientId.substring(12, 13);

  //     if(checksum !== "") {

  //       let arr = [...patientId];
  //       let sum = 0;
  //       let n = String(patientId).length;

  //       for(let i = 0; i < n; i++){
  //         arr[i] = parseInt(arr[i]);
  //       }

  //       for(let i = 1; i < n; i = i + 2){
  //         var v = arr[n-1-i]*2;
  //         if(v > 9){ arr[n - 1 - i] = v - 9; }
  //         else{ arr[n-1-i] = v; }
  //       }

  //       for(let i = 0; i < n; i++){
  //         sum = sum + arr[i];
  //       }

  //       if(sum % 10 === 0) checksumValidation = false;
  //       else checksumValidation = true;

  //     }

  //     const IdVerification = Boolean(checksumValidation);
  //     return IdVerification;
  // };


    return ( 

      <main id='main'>
        <div className={classNames(styles.patient_info, styles.block, styles.block_layout)}>
          <div className={classNames(styles.block_item1)}>
            <div className={classNames(styles.flex, styles.flex_layout)}>
              <header
                className={classNames(styles.content_box1, styles.content_box1_layout)}>
                <h1 className={classNames(styles.hero_title, styles.hero_title_layout)}>
                  {'Patient Information'}
                </h1>
                <h1 className={classNames(styles.big_title, styles.big_title_layout)}>
                {new Date().toLocaleString("en-ZA", { day : '2-digit', month: "long" } )} {new Date().getFullYear()}
                </h1>
              </header>

              <div className={classNames(styles.actions_box, styles.actions_box_layout)}>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" startIcon={<KeyboardBackspaceIcon />} onClick={() => navigate(-1)} style={{ backgroundColor: '#122795', color: '#fff', marginRight: '20px'}}>
                    Return
                  </Button>
                </Stack>
              </div>
    
              <div className={classNames(styles.box_add_patient, styles.box_layout_add_patient)} >

                  
                    <form
                    noValidate
                    autoComplete='off'
                    className={ classes.formStyle }
                    onSubmit={handleSubmit}
                    // onSubmit={calculate}
                    >
                    <Container maxWidth='xl' className={ classes.muiCustom }>
                    <Typography variant='h5'>Personal Details</Typography>
                      <Box
                          sx={{
                            display: 'grid',
                            gap: 4,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                          }}
                        >
                          
                            
                            <TextField 
                              required
                              fullWidth
                              id='patientName'
                              label='Full Names'
                              variant='outlined'
                              className={ classes.spacing }
                              value={patient.patientName}
                              onChange={(e)=> setPatientInfo({...patientData, patientName: e.target.value})}
                              inputProps={{ style: { textTransform: 'capitalize' } }}
                              autoFocus
                            />

                            <TextField 
                              required
                              fullWidth
                              id='patientSurname'
                              label='Surname'
                              variant='outlined'
                              className={ classes.spacing }
                              value={patient.patientSurname}
                              onChange={(e)=> setPatientInfo({...patientData, patientSurname: e.target.value})}
                              inputProps={{ style: { textTransform: 'capitalize' } }}
                              autoFocus
                            />

                            <TextField 
                              required
                              fullWidth
                              id='patientId'
                              type='number'
                              label='ID Number'
                              variant='outlined'
                              className={ classes.spacing }
                              value={ patient.patientId }
                              onChange={(e)=> setPatientInfo({...patientData, patientId: e.target.value})}
                              autoFocus
                              // error={validateId(patient.patientId)}
                              // helperText={validateId(patient.patientId) === true ? 'ID is Invalid' : ''}
                            />

                            <TextField 
                              
                              fullWidth
                              id='patientBirthDate'
                              type='date'
                              helperText='Patient Birth Date'
                              variant='outlined'
                              className={ classes.spacing }
                              value={ moment(patient.patientBirthDate).format('YYYY-MM-DD') }
                              onChange={(e)=> setPatientInfo({...patientData, patientBirthDate: e.target.value})}
                              autoFocus
                            />

                            <TextField 
                              fullWidth
                              id='patientAge'
                              type='number'
                              label='Patient Age'
                              variant='outlined'
                              className={ classes.spacing }
                              value={patient.patientAge}
                              onChange={(e)=> setPatientInfo({...patientData, patientAge: e.target.value})}
                              autoFocus
                            />

                            <TextField 
                              required
                              fullWidth
                              id="patientGender"
                              select
                              label="Patient Gender"
                              variant='outlined'
                              className={ classes.spacing }
                              value={(gender, patient.patientGender)}
                              onChange={(handleChange, (e)=> setPatientInfo({...patientData, patientGender: e.target.value}))}
                              autoFocus
                              helperText="Please select patient gender"
                            >
                                  {genders.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                      </MenuItem>
                                  ))}
                            </TextField>

                            <Autocomplete
                              disablePortal
                              id="patientHomeLanguage"
                              onChange={ (e) => setPatientInfo({...patientData, patientHomeLanguage: e.target.value})}
                              options={homeLanguages}
                              fullWidth
                              renderInput={(params) => <TextField variant="outlined"{...params} label="Home Language" />}
                              className={ classes.spacing }
                              autoFocus
                              value={patient.patientHomeLanguage}
                            />
    
                            <TextField 
                              required
                              fullWidth
                              id="patientCitizenship"
                              select
                              label="Patient Citizenship"
                              variant='outlined'
                              className={ classes.spacing }
                              value={(patientCitizenshipClass, patient.patientCitizenship)}
                              onChange={(handleChange, (e)=> setPatientInfo({...patientData, patientCitizenship: e.target.value}))}
                              autoFocus
                              helperText="Please select patient citizenship classification"
                            >
                                  {citizenships.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                      </MenuItem>
                                  ))}
                            </TextField>

                            <FormControl sx={{ m: 20 }} variant="outlined" style={{width: '100%'}}>
                                <OutlinedInput
                                fullWidth
                                    id="patientWeight"
                                    value={(values.weight, patient.patientWeight)}
                                    onChange={(handleWHChange('weight'), (e) => setPatientInfo({...patientData, patientWeight: e.target.value}))}
                                    endAdornment={<InputAdornment position="end">kg's</InputAdornment>}
                                    aria-describedby="patientWeight-helper-text"
                                    inputProps={{
                                    'aria-label': 'weight',
                                    }}
                                    className={ classes.spacing }
                                />
                                <FormHelperText id="patientWeight-helper-text">Patient Weight</FormHelperText>
                            </FormControl>
    
                            <FormControl sx={{ m: 20 }} variant="outlined" style={{width: '100%'}}>
                                <OutlinedInput
                                fullWidth
                                    id="patientHeight"
                                    value={(values.height, patient.patientHeight)}
                                    onChange={(handleWHChange('height'), (e) => setPatientInfo({...patientData, patientHeight: e.target.value}))}
                                    endAdornment={<InputAdornment position="end">cm's</InputAdornment>}
                                    aria-describedby="patientHeight-helper-text"
                                    inputProps={{
                                    'aria-label': 'height',
                                    }}
                                    className={ classes.spacing }
                                />
                                <FormHelperText id="patientHeight-helper-text">Patient Height</FormHelperText>
                            </FormControl>
    
                            <FormControl sx={{ m: 20 }} variant="outlined" style={{width: '100%'}}>
                                <OutlinedInput
                                fullWidth
                                    id="patientBMI"
                                    value={ patient.patientBMI }
                                    onChange={(e) => setPatientInfo({...patientData, patientBMI: e.target.value})}
                                    endAdornment={<InputAdornment position="end">BMI</InputAdornment>}
                                    aria-describedby="patientBMI-helper-text"
                                    inputProps={{
                                    'aria-label': 'height',
                                    }}
                                    className={ classes.spacing }
                                />
                                <FormHelperText id="patientBMI-helper-text">Patient BMI</FormHelperText>
                            </FormControl>
                              
                          
                      </Box>
                    </Container>

                    <Container maxWidth='xl' className={ classes.muiCustom }>
                    <Typography variant='h5'>Financial and Next of Kin Details</Typography>
                      <Box
                          sx={{
                            display: 'grid',
                            gap: 4,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                          }}
                        >

                          <Autocomplete
                            disablePortal
                            id="patientMedicalScheme"
                            onChange={ (e) => setPatientInfo({...patientData, patientMedicalScheme: e.target.value})}
                            options={medicalSchemes}
                            fullWidth
                            className={ classes.spacing }
                            renderInput={(params) => <TextField {...params} label="Medical Scheme" variant="outlined"/>}
                            autoFocus
                            value={patient.patientMedicalScheme}
                          />                       
                        
                      </Box>
                          <Stack direction="row" spacing={2} justifyContent='end' >
                            <Button 
                              variant='outlined'
                              type='submit'
                              className={ classes.spacing }
                              onClick={() => navigate(-1)}
                              >
                                  Cancel
                            </Button>
                            <Button 
                              variant='outlined'
                              type='submit'
                              style={{ backgroundColor: '#122795', color: '#fff', marginTop: '20px'}}
                              >
                                  Update Patient
                            </Button>

                          </Stack>
                    </Container>
                    </form>
                  
              </div>     
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>

     );
}
 
export default PatientUpdate;