import { combineReducers } from 'redux';
import authReducer from "./authReducer";
import taskReducer from "./taskReducer";
import patientsReducer from "./patientsReducer";
import noteReducer from './noteReducer';
import patientReducer from './patientReducer';
import consultsReducer from './consultsReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  tasks: taskReducer,
  patients: patientsReducer,
  patient: patientReducer,
  notes: noteReducer,
  consults: consultsReducer
});

export default rootReducer;



