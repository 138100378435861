import React, { useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { addPatientInfo } from '../../store/actions/patientsActions';
import { useNavigate } from 'react-router-dom';

import { Typography, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import { capitalize } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { Autocomplete, Box, Stack } from '@mui/material';

import { ToastContainer } from 'react-toastify';

import classNames from 'classnames';
import styles from '../assets/css/AddPatient.module.css';
import main from '../../App.module.css';

import medicalSchemes from './medicalSchemes'
import homeLanguages from './languages'

const genders = [
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
];

const citizenships = [
  {
    value: '0',
    label: 'SA Citizen',
  },
  {
    value: '1',
    label: 'Permanent Resident',
  },
];

const useStyles = makeStyles({
    formStyle: {
        padding: '30px',
        maxWidth: '1500px',
        width: '100%'
    },
    spacing: {
        marginTop: '20px'
    },
    muiCustom: {
      borderRadius: '30px',
      margin: '25px',
      padding: '50px',
      boxShadow: '0px 0px 12px -3px #000000'
    },
    muiAddPatientBtn: {
      backgroundColor: '#122795', 
      color: '#fff',
      '&:hover': {
        backgroundColor: "#4caf50",
        color: '#fff'
     },
    },
    muiAddPatientBtnDisabled: {
      backgroundColor: '#808080', 
      color: '#000'
    }
});

const AddPatient = () => {

  const classes = useStyles();

  const [gender, setGender] = useState('');
  const [patientCitizenshipClass, setPatientCitizenshipClass] = useState('');

  const handleChange = (event) => {
      setGender(event.target.value);
      setPatientCitizenshipClass(event.target.value);
  };

  const [values, setValues] = useState({
      weight: '',
      height: '',
    });

  const handleWHChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  // const auth = useSelector(state=> state.auth)
  const dispatch = useDispatch();
  const [patientData, setPatientInfo] = useState({
    patientName: "",
    patientSurname: "",
    patientGender: "",
    patientCitizenship: "",
    patientId: "",
    patientBirthDate: "",
    patientHomeLanguage: "",
    patientAge: "",
    patientWeight: "",
    patientHeight: "",
    patientBMI: "",
    knownChronicIllnesses: "",
    patientMedicalScheme: ""
  })

  const handleSubmit = (e)=> {
      e.preventDefault()
      dispatch(addPatientInfo(patientData))
      setPatientInfo({
        patientName: "",
        patientSurname: "",
        patientGender: "",
        patientCitizenship: "",
        patientId: "",
        patientBirthDate: "",
        patientHomeLanguage: "",
        patientAge: "",
        patientWeight: "",
        patientHeight: "",
        patientBMI: "",
        knownChronicIllnesses: "",
        patientMedicalScheme: ""
      })
  }

  const calculateDOB = (patientId) => {

    let year = "";
    const dd = Number(patientId.substring(4, 6));
    const mm = Number(patientId.substring(2, 4));
    year = patientId[0] <= 1 ? Number(20 + patientId.substring(0, 2)) : Number(19 + patientId.substring(0, 2));
      if(year !== "") {
        const formatMonthAndDay = (value, figures) => value.toString().padStart(2, "0");
        const day = formatMonthAndDay(dd, 2);
        const month = formatMonthAndDay(mm, 2);
          // console.log("DOB ", year + "-" + month + "-" + day);
        patientData.patientBirthDate = year + "-" + month + "-" + day;
        return year + "-" + month + "-" + day;
      }
  };
  
  const calculateAge = (patientId) => {

    let year = "";
    const dd = Number(patientId.substring(4, 6));
    const mm = Number(patientId.substring(2, 4));
    year = patientId[0] <= 1 ? Number(20 + patientId.substring(0, 2)) : Number(19 + patientId.substring(0, 2));
        const formatMonthAndDay = (value, figures) => value.toString().padStart(2, "0");
        const day = formatMonthAndDay(dd, 2);
        const month = formatMonthAndDay(mm, 2);
        //   // console.log("DOB ", year + "-" + month + "-" + day);
        const calculatedDOB = year + "-" + month + "-" + day;
        let today = new Date();
        let birthDate = new Date(calculatedDOB);
        let age = "";
        let getAge = "";
        if(dd !== 0 && mm !== 0) {
          getAge = today.getFullYear() - birthDate.getFullYear();
        }
        let m = today.getMonth() - birthDate.getMonth();
        if(getAge !== "") {

          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
          {
              getAge--;
          }
        }

        age = getAge;

        patientData.patientAge = age;
        return age;
  };

  const getGender = (patientId) => {

      let gender = "";
      const genderString = Number(patientId.substring(6, 10));
      const charCheck = String(genderString);
      if(charCheck.length === 4) {
        gender = genderString <= 4999 ? "Female" : "Male";
      }
  
      const verifiedGender = gender;
      patientData.patientGender = verifiedGender;
      return verifiedGender;

  };

  const getCitizenship = (patientId) => {

    let citizenship = "";
    const citizenshipValue = patientId.substring(10, 11);

    if(citizenshipValue !== "") {
      citizenship = Number(citizenshipValue);
    }

    const verifiedCitizenship = citizenship;
    patientData.patientCitizenship = verifiedCitizenship;
    return verifiedCitizenship;
  };

  const validateId = (patientId) => {

      let checksumValidation = "";
      const checksum = patientId.substring(12, 13);

      if(checksum !== "") {

        let arr = [...patientId];
        let sum = 0;
        let n = String(patientId).length;

        for(let i = 0; i < n; i++){
          arr[i] = parseInt(arr[i]);
        }

        for(let i = 1; i < n; i = i + 2){
          var v = arr[n-1-i]*2;
          if(v > 9){ arr[n - 1 - i] = v - 9; }
          else{ arr[n-1-i] = v; }
        }

        for(let i = 0; i < n; i++){
          sum = sum + arr[i];
        }

        if(sum % 10 === 0 && checksum) checksumValidation = false;  
          else checksumValidation = true;

      }

      const IdVerification = Boolean(checksumValidation);

      return IdVerification;
      
  };

  const [height, setHeight] = useState(0);
  const [mass, setMass] = useState(0);


  const calculateBMI = (mass, height) => {

  const formValid = +height > 0 && +mass > 0;
  if (!formValid) {
    return;
  }
  const bmi = +mass / (+height*0.01) ** 2;
  patientData.patientBMI = bmi;
  return bmi;
  };

  const navigate = useNavigate();

  const refs = {
    personalDetails : useRef(null),
    financialAndKin : useRef(null)
  }

  const scrollToPersonalElement = () => refs.personalDetails.current.scrollIntoView();
  const scrollToFinanciallElement = () => refs.financialAndKin.current.scrollIntoView();

    // if(auth._id) return <Navigate to="/" />
    
    return (
          <>
              <header
                className={classNames(styles.content_box1, styles.content_box1_layout)}>
                <h1 className={classNames(styles.hero_title, styles.hero_title_layout)}>
                  {'Patient Information'}
                </h1>
                <h1 className={classNames(styles.big_title, styles.big_title_layout)}>
                {new Date().toLocaleString("en-ZA", { day : '2-digit', month: "long" } )} {new Date().getFullYear()}
                </h1>
              </header>
              <main id='main' className={classNames(main.main)}>
                <div id='FlexContainer' className={classNames(styles.flex, styles.flex_layout)}>
                  <div className={classNames(styles.actions_box, styles.actions_box_layout)}>
                    <Stack direction="row" spacing={2}>
                      <Button variant="contained" endIcon={<AccountCircleTwoToneIcon />} onClick={scrollToPersonalElement} style={{ backgroundColor: '#444', color: '#fff', marginRight: '20px'}}>
                        Personal Details
                      </Button>
                      <Button variant="contained" endIcon={<PaymentTwoToneIcon />} onClick={scrollToFinanciallElement} style={{ backgroundColor: '#444', color: '#fff', marginRight: '20px'}}>
                        Financial Details
                      </Button>
                      <Button variant="contained" startIcon={<KeyboardBackspaceIcon />} onClick={() => navigate(-1)} style={{ backgroundColor: '#122795', color: '#fff', marginRight: '20px'}}>
                        Return
                      </Button>
                    </Stack>
                  </div>
        
                  <div className={classNames(styles.box_add_patient, styles.box_layout_add_patient)} >

                      
                        <form
                        noValidate
                        autoComplete='off'
                        className={ classes.formStyle }
                        onSubmit={handleSubmit}
                        // onSubmit={calculate}
                        >
                        <Container ref={refs.personalDetails} maxWidth='xl' className={ classes.muiCustom }>
                        <Typography variant='h5'>Personal Details</Typography>
                          <Box
                              sx={{
                                display: 'grid',
                                gap: 4,
                                gridTemplateColumns: 'repeat(2, 1fr)',
                              }}
                            >
                              
                                
                                <TextField 
                                  required
                                  fullWidth
                                  id='patientName'
                                  label='Full Names'
                                  variant='outlined'
                                  className={ classes.spacing }
                                  value={capitalize(patientData.patientName)}
                                  onChange={(e)=> setPatientInfo({...patientData, patientName: e.target.value})}
                                  inputProps={{ style: { textTransform: 'capitalize' } }}
                                  autoFocus
                                />

                                <TextField 
                                  required
                                  fullWidth
                                  id='patientSurname'
                                  label='Surname'
                                  variant='outlined'
                                  className={ classes.spacing }
                                  value={capitalize(patientData.patientSurname)}
                                  onChange={(e)=> setPatientInfo({...patientData, patientSurname: e.target.value})}
                                  inputProps={{ style: { textTransform: 'capitalize' } }}
                                  autoFocus
                                />

                                <TextField 
                                  required
                                  fullWidth
                                  id='patientId'
                                  type='number'
                                  label='ID Number'
                                  variant='outlined'
                                  className={patientData.patientId.length < 13 && validateId(patientData.patientId) !== true ? classNames(classes.spacing) : classNames(styles.correctId, classes.spacing)}
                                  value={patientData.patientId}
                                  onChange={(e)=> setPatientInfo({...patientData, patientId: e.target.value})}
                                  autoFocus
                                  error={validateId(patientData.patientId)}
                                  helperText={patientData.patientId.length < 13 ? '' : ( validateId(patientData.patientId) !== true ? 'Valid ID' : 'Invalid ID')}
                                />

                                <TextField 
                                  fullWidth
                                  id='patientBirthDate'
                                  type='date'
                                  helperText='Please input ID Patient Birth Date Association'
                                  variant='outlined'
                                  className={ classes.spacing }
                                  value={(patientData.patientBirthDate, calculateDOB(patientData.patientId))}
                                  onChange={(e)=> setPatientInfo({...patientData, patientBirthDate: e.target.value})}
                                  autoFocus
                                  disabled={calculateDOB(patientData.patientId) !== '' ? true : false}
                                />

                                <TextField 
                                  fullWidth
                                  id='patientAge'
                                  type='number'
                                  label='Patient Age'
                                  variant='outlined'
                                  className={ classes.spacing }
                                  value={(patientData.patientAge, calculateAge(patientData.patientId))}
                                  onChange={(e)=> setPatientInfo({...patientData, patientAge: e.target.value})}
                                  autoFocus
                                  disabled={calculateAge(patientData.patientId) !== '' ? true : false}
                                />

                                <TextField 
                                  required
                                  fullWidth
                                  id="patientGender"
                                  select
                                  label="Patient Gender"
                                  variant='outlined'
                                  className={ classes.spacing }
                                  value={(gender, getGender(patientData.patientId))}
                                  onChange={(handleChange, (e)=> setPatientInfo({...patientData, patientGender: e.target.value}))}
                                  autoFocus
                                  helperText="Please input ID for patient gender classification"
                                  disabled={getGender(patientData.patientId) !== '' ? true : false}
                                >
                                      {genders.map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                          </MenuItem>
                                      ))}
                                
                                </TextField>

                                <Autocomplete
                                  disablePortal
                                  id="patientHomeLanguage"
                                  options={homeLanguages}
                                  getOptionLabel={(option) => option.label}
                                  onChange={(event, value) => setPatientInfo({...patientData, patientHomeLanguage: value.value}) }
                                  fullWidth
                                  className={ classes.spacing }
                                  renderInput={(params) => <TextField  variant="outlined"{...params} label="Home Language" />}
                                  autoFocus
                                  autoHighlight
                                />
        
                                <TextField 
                                  required
                                  fullWidth
                                  id="patientCitizenship"
                                  select
                                  label="Patient Citizenship"
                                  variant='outlined'
                                  className={ classes.spacing }
                                  value={(patientCitizenshipClass, patientData.patientCitizenship, getCitizenship(patientData.patientId))}
                                  onChange={(handleChange, (e)=> setPatientInfo({...patientData, patientCitizenship: e.target.value}))}
                                  autoFocus
                                  helperText="Please input ID for patient citizenship classification"
                                  disabled={getCitizenship(patientData.patientId) !== '' ? true : false}
                                >
                                      {citizenships.map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                          </MenuItem>
                                      ))}
                                </TextField>

                                <FormControl sx={{ m: 20 }} variant="outlined" style={{width: '100%'}}>
                                    <OutlinedInput
                                    fullWidth
                                        id="patientWeight"
                                        value={(values.weight, mass, patientData.patientWeight)}
                                        onChange={(handleWHChange('weight'), (e) => setMass(e.target.value), (e) => setPatientInfo({...patientData, patientWeight: e.target.value}))}
                                        endAdornment={<InputAdornment position="end">kg's</InputAdornment>}
                                        aria-describedby="patientWeight-helper-text"
                                        inputProps={{
                                        'aria-label': 'weight',
                                        }}
                                        className={ classes.spacing }
                                    />
                                    <FormHelperText id="patientWeight-helper-text">Patient Weight</FormHelperText>
                                </FormControl>
        
                                <FormControl sx={{ m: 20 }} variant="outlined" style={{width: '100%'}}>
                                    <OutlinedInput
                                    fullWidth
                                        id="patientHeight"
                                        value={(values.height, height, patientData.patientHeight)}
                                        onChange={(handleWHChange('height'), (e) => setHeight(e.target.value), (e) => setPatientInfo({...patientData, patientHeight: e.target.value}))}
                                        endAdornment={<InputAdornment position="end">cm's</InputAdornment>}
                                        aria-describedby="patientHeight-helper-text"
                                        inputProps={{
                                        'aria-label': 'height',
                                        }}
                                        className={ classes.spacing }
                                    />
                                    <FormHelperText id="patientHeight-helper-text">Patient Height</FormHelperText>
                                </FormControl>
        
                                <FormControl sx={{ m: 20 }} variant="outlined" style={{width: '100%'}}>
                                    <OutlinedInput
                                    fullWidth
                                        id="patientBMI"
                                        value={(calculateBMI(patientData.patientWeight, patientData.patientHeight), patientData.patientBMI)}
                                        onChange={(e) => setPatientInfo({...patientData, patientBMI: e.target.value})}
                                        endAdornment={<InputAdornment position="end">BMI</InputAdornment>}
                                        aria-describedby="patientBMI-helper-text"
                                        inputProps={{
                                        'aria-label': 'height',
                                        }}
                                        className={ classes.spacing }
                                    />
                                    <FormHelperText id="patientBMI-helper-text">Patient BMI</FormHelperText>
                                </FormControl>

                                {
                                patientData.patientName && 
                                patientData.patientSurname && 
                                patientData.patientId && 
                                patientData.patientHomeLanguage && 
                                patientData.patientHeight && 
                                patientData.patientWeight && 
                                patientData.patientBMI !== '' ? 
                                 (
                                  <div className={classNames(styles.pulse, styles.personalDetailsCheckPosition)}>
                                    <FactCheckOutlinedIcon className={classNames(styles.personalDetailsCheck, styles.bgPulse)} />
                                  </div>
                                 )
                                 : ''
                                }
                                
                                  
                              
                          </Box>
                        </Container>

                        <Container ref={refs.financialAndKin} maxWidth='xl' className={ classes.muiCustom }>
                        <Typography variant='h5'>Financial and Next of Kin Details</Typography>
                          <Box
                              sx={{
                                display: 'grid',
                                gap: 4,
                                gridTemplateColumns: 'repeat(2, 1fr)',
                              }}
                            >

                              <Autocomplete
                                disablePortal
                                id="patientMedicalScheme"
                                options={medicalSchemes}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, value) => setPatientInfo({...patientData, patientMedicalScheme: value.value}) }
                                fullWidth
                                className={ classes.spacing }
                                renderInput={(params) => <TextField {...params} label="Medical Scheme" variant="outlined" />}
                                autoFocus
                                autoHighlight
                              />                       
                            
                          </Box>
                              <Stack direction="row" spacing={2} justifyContent='end' >
                                <Button 
                                  variant='outlined'
                                  type='submit'
                                  className={ classes.spacing }
                                  onClick={() => navigate(-1)}
                                  >
                                      Cancel
                                </Button>
                                <Button
                                  disabled={validateId(patientData.patientId) !== true && patientData.patientId.length === 13 ? false : true}
                                  variant='outlined'
                                  type='submit'
                                  className={validateId(patientData.patientId) !== true && patientData.patientId.length === 13 ? classes.muiAddPatientBtn : classes.muiAddPatientBtnDisabled }
                                  style={{ marginTop: '20px'}}
                                  >
                                      Add Patient
                                </Button>

                              </Stack>
                        </Container>
                        </form>
                      
                  </div>
                </div>    
              </main>

              <ToastContainer />
            </>
        
      );
}
 
export default AddPatient;