import axios from 'axios';
import { url, setHeaders } from '../../api';
import { toast } from 'react-toastify';

export const getPatients = ()=> {
    return (dispatch, getState)=> {
        axios
        .get(`${url}/getPatients`, setHeaders())
        .then(patients=> {

            dispatch({
                type: 'GET_PATIENTS',
                patients
            })
        })
        .catch(error=> {
            console.log(error.response)

            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }
}

export const searchPatients = (searchQuery)=> {
    return (dispatch, getState)=> {
        axios
        .get(`${url}/searchPatients/${searchQuery}`, setHeaders())
        .then(patient=> {

            dispatch({
                type: 'SEARCH_PATIENTS',
                patient
            })
        })
        .catch(error=> {
            console.log(error.response)

            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }
}

export const addPatientInfo = (patientData)=> {
    return (dispatch, getState)=> {
        axios
        .post(`${url}/addPatient`, patientData, setHeaders())
        .then(patientData=> {

            dispatch({
                type: 'ADD_PATIENT',
                patientData
            })
        })
        .catch(error=> {
            console.log(error.response)

            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }
}

// return (dispatch, getState)=> {
//     axios
//     .post(`${url}/addPatient`, patientData)
//     .then(securetoken=> {
//         localStorage.setItem('securetoken', securetoken.data)

//         dispatch({
//             type: 'ADD_PATIENT',
//             securetoken: securetoken.data
//         })
//     })
//     .catch(error=> {
//         console.log(error.response)

//         toast.error(error.response?.data, {
//             position: toast.POSITION.BOTTOM_RIGHT
//         });
//     })
// }
// }

// export const createUser = (user)=> {
//     return (dispatch)=> {
//         axios
//         .post(`${url}/createUser`, user)
//         .then(securetoken=> {
//             localStorage.setItem('securetoken', securetoken.data)

//             dispatch({
//                 type: 'CREATE_USER',
//                 securetoken: securetoken.data
//             })
//         })
//         .catch(error=> {
//             console.log(error.response)

//             // toast.error(error.response?.data) {
//             //     position: toast.POSITION.BOTTOM_RIGHT
//             // }
//         })
//     }
// }

export const loadUser = ()=> {
    return (dispatch, getState)=> {

        const secureToken = getState().auth.secureToken

        if(secureToken){
            dispatch({
                type: "USER_LOADED",
                secureToken
            })
        } else return null
    }
}