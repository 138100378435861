import React, { useState } from 'react'

import styles from '../../App.module.css';
import classNames from 'classnames';

import { NavLink, useLocation } from 'react-router-dom';

import { Button, Stack } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';


import DashboardIcon from '@mui/icons-material/Dashboard';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

import main from '../../App.module.css';

const SideBar = ({handleSignOut}) => {

    const location = useLocation();

    // const handleSignOut = () => {
    //     dispatch(signOut());
    //   };

    // const dispatch = useDispatch;

    const [shown, setShown] = useState(true);

    const showSidebar = () => {
      setShown(true);

      document.getElementById("main").className = classNames(main.main);
      document.getElementById("FlexContainer").className = classNames(main.flex, main.flex_layout);

    };
  
    const drawerClosed = () => {
      setShown(false);

      document.getElementById("main").className = classNames(main.mainExpanded);
      document.getElementById("FlexContainer").className = classNames(main.mainFlexExpanded);

    };

  return (
    <>
    <aside>
      <div className={classNames(styles.block_item)}>
        <div className={classNames(styles.content_box, styles.content_box_layout)}>
          <button className={classNames(styles.SidebarToggle)} onClick={shown === false ? showSidebar : drawerClosed}>
            <div className={classNames(styles.cover_block, styles.cover_block_layout)}>
              <div className={classNames(styles.box4, styles.box4_layout)} />
              <h1
                className={classNames(
                  styles.hero_title1_box,
                  styles.hero_title1_box_layout
                )}>
                <pre className={classNames(styles.hero_title1)}>{'OS\nKY'}</pre>

              </h1>
            </div>
          </button>
          <div className={shown === true ? classNames(styles.sideBar_layOut) : classNames(styles.sideBar_layOut_mini)} style={{ height: '100vh' }}>
            {shown === true ? (
              <NavLink to="/"
                className={({ isActive }) => isActive ? classNames(styles.cover_block1, styles.cover_block1_layout, styles.big_title2_layout, styles.big_title2) : classNames(styles.big_title1, styles.big_title1_layout)}>
                {'Home'}
              </NavLink>
            ) : (
              <NavLink to="/"
                className={({ isActive }) => isActive ? classNames(styles.cover_block_icon_active, styles.cover_block_icon_layout, styles.big_title2_layout, styles.big_title2) : classNames(styles.big_title2, styles.big_title2_layout, styles.cover_block_icon_layout, styles.cover_block_icon_notActive, styles.cover_block_icon_notActive_color)}>
                <DashboardIcon />
              </NavLink>
            )}

            {shown === true ? (
              <NavLink to="/calendar" className={() => location.pathname === '/calendar' || location.pathname === '/expandcalendar' ? classNames(styles.cover_block1, styles.cover_block1_layout, styles.big_title2_layout, styles.big_title2) :
                classNames(styles.big_title1, styles.big_title1_layout)}>{'Calendar'}
              </NavLink>
            ) : (
              <NavLink to="/calendar" className={() => location.pathname === '/calendar' || location.pathname === '/expandcalendar' ? classNames(styles.cover_block_icon_active, styles.cover_block_icon_layout, styles.big_title2_layout, styles.big_title2) :
                classNames(styles.cover_block_icon_notActive, styles.cover_block_icon_layout, styles.cover_block_icon_notActive_color, styles.big_title2_layout, styles.big_title2)}>
                <CalendarMonthIcon />
              </NavLink>
            )}

            {shown === true ? (
              <NavLink to="/patientOverview" className={() => location.pathname === '/patients' || location.pathname === '/addPatient' || location.pathname === '/patient' || location.pathname === '/patientOverview' ? classNames(styles.cover_block1, styles.cover_block1_layout, styles.big_title2_layout, styles.big_title2) :
                classNames(styles.big_title1, styles.big_title1_layout)}>{'Patient'}
              </NavLink>
            ) : (
              <NavLink to="/patientOverview" className={() => location.pathname === '/patients' || location.pathname === '/addPatient' || location.pathname === '/patient' || location.pathname === '/patientOverview' ? classNames(styles.cover_block_icon_active, styles.cover_block_icon_layout, styles.big_title2_layout, styles.big_title2) :
                classNames(styles.cover_block_icon_notActive, styles.cover_block_icon_layout, styles.cover_block_icon_notActive_color, styles.big_title2_layout, styles.big_title2)}>
                <MedicalInformationIcon />
              </NavLink>
            )}

            {shown === true ? (
              <NavLink to="/billing" className={() => location.pathname === '/billing' || location.pathname === '/manageBilling' ? classNames(styles.cover_block1, styles.cover_block1_layout, styles.big_title2_layout, styles.big_title2) :
                classNames(styles.big_title1, styles.big_title1_layout)}>{'Billing'}
              </NavLink>
            ) : (
              <NavLink to="/billing" className={() => location.pathname === '/billing' || location.pathname === '/manageBilling' ? classNames(styles.cover_block_icon_active, styles.cover_block_icon_layout, styles.big_title2_layout, styles.big_title2) :
                classNames(styles.cover_block_icon_notActive, styles.cover_block_icon_layout, styles.cover_block_icon_notActive_color, styles.big_title2_layout, styles.big_title2)}>
                <CreditCardIcon />
              </NavLink>
            )}

            {shown === true ? (
              <NavLink to="/settings" className={() => location.pathname === '/settings' || location.pathname === '/manageSettings' ? classNames(styles.cover_block1, styles.cover_block1_layout, styles.big_title2_layout, styles.big_title2) :
                classNames(styles.big_title1, styles.big_title1_layout)}>{'Settings'}
              </NavLink>
            ) : (
              <NavLink to="/settings" className={() => location.pathname === '/settings' || location.pathname === '/manageSettings' ? classNames(styles.cover_block_icon_active, styles.cover_block_icon_layout, styles.big_title2_layout, styles.big_title2) :
                classNames(styles.cover_block_icon_notActive, styles.cover_block_icon_layout, styles.cover_block_icon_notActive_color, styles.big_title2_layout, styles.big_title2)}>
                <SettingsApplicationsIcon />
              </NavLink>
            )}

            <Stack direction="row" spacing={2}>

              {shown === true ? (
                <Button 
                  variant="contained" 
                  startIcon={<LogoutIcon />} 
                  onClick={() => handleSignOut()} 
                  className={classNames(styles.signOutBtn)}
                >
                  Sign Out
                </Button>

              ) : (

                <Button onClick={() => handleSignOut()} style={{
                  backgroundColor: '#fff',
                  color: '#122795',
                  position: 'relative',
                  height: 'min-content',
                  width: '37.5%',
                  padding: '22px 29.17% 22px 19.72%',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  font: '25px "Inter", Helvetica, Arial, serif',
                  textAlign: 'center',
                  letterSpacing: '0px',
                  marginTop: '42px',
                  marginLeft: '-8px',
                  borderTopRightRadius: '30px',
                  borderBottomRightRadius: '30px',
                  border: '1px solid #112694'
                }}
                className={classNames(styles.iconSignOutBtn)}>
                  <LogoutIcon />
                </Button>

              )}

            </Stack>
          </div>
        </div>
      </div>
    </aside>
    </>
  );
}

export default SideBar