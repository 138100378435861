import axios from 'axios';
import { url, setHeaders } from '../../api';
import { toast } from 'react-toastify';

export const signIn = (creds)=> {
    return (dispatch)=> {
        axios
        .post(`${url}/signin`, creds, setHeaders())
        .then(securetoken => {
            
            localStorage.setItem('securetoken', securetoken.data)

            if (securetoken.data !== '') {
                window.location.pathname = "/"
            }

            dispatch({
                type: 'SIGN_IN',
                securetoken: securetoken.data
            })

        })
        .catch(error=> {
            console.log(error.response)

            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }
}

export const signOut = () => {
    return (dispatch) => {
      
      dispatch({
        type: "SIGN_OUT",
      });
  
    };
  };

// export const createUser = (user)=> {
//     return (dispatch)=> {
//         axios
//         .post(`${url}/createUser`, user)
//         .then(securetoken=> {
//             localStorage.setItem('securetoken', securetoken.data)

//             dispatch({
//                 type: 'CREATE_USER',
//                 securetoken: securetoken.data
//             })
//         })
//         .catch(error=> {
//             console.log(error.response)

//             // toast.error(error.response?.data) {
//             //     position: toast.POSITION.BOTTOM_RIGHT
//             // }
//         })
//     }
// }

export const loadUser = ()=> {

    return (dispatch, getState)=> {

        const secureToken = getState().auth.secureToken;

        if(secureToken){
            dispatch({
                type: "USER_LOADED",
                secureToken
            })
            
        } else return null
    }
}