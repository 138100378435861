import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { signIn } from '../../store/actions/authActions';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import styles from '../../SignIn.module.css';
import classNames from 'classnames';
// import { useLocation, useNavigate } from 'react-router-dom';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://osky.com/">
        Osky (PTY) Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

// const useStyles = makeStyles({
//     formStyle: {
//         margin: '0px auto',
//         padding: '30px',
//         borderRadius: '9px',
//         boxShadow: '0px 0px 12px -3px #000000'
//     },
//     spacing: {
//         marginTop: '20px'
//     }
// });

const SignIn = () => {

    const dispatch = useDispatch();

    // const location = useLocation();
    // const navigate = useNavigate();

    const [creds, setCreds] = useState({
        email: "",
        password: ""
    })

    // const [signedIn, setSignedIn] = useState(false)

    // const prevLocation = location.pathname;

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(signIn(creds))
        setCreds({
            email: "",
            password: ""
        })
        // setTimeout(() => {
        //  const approved =  localStorage.getItem('securetoken');
        //  approved !== null ? setSignedIn(true) : setSignedIn(false);
        //   signedIn === true ? navigate(prevLocation) : setSignedIn(false);
        // }, 1500);
    }

    // console.log(window.innerWidth)

     return (
        <ThemeProvider theme={theme}>
          <Grid container className={classNames(styles.signInForm)} sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              className={classNames(styles.leftGrid)}
              // sx={{
              //   backgroundImage: 'url(https://source.unsplash.com/random)',
              //   backgroundRepeat: 'no-repeat',
              //   backgroundColor: (t) =>
              //     t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              //   backgroundSize: 'cover',
              //   backgroundPosition: 'center',
              // }}
            >

            <video autoPlay className={classNames(styles.loginBgVideo)} src={require('../assets/bg-login.mp4')} type="video/mp4" loop>
            </video>
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="enter-email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={creds.email}
                    onChange={(e)=> setCreds({...creds, email: e.target.value})}
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="enter-password"
                    autoComplete="current-password"
                    value={creds.password}
                    onChange={(e)=> setCreds({...creds, password: e.target.value})}
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="#" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </Box>
            </Grid>
            <ToastContainer />
          </Grid>
        </ThemeProvider>
        
      );
}

 
export default SignIn;