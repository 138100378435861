import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";


const initialState = {
    secureToken: localStorage.getItem('securetoken'),
    name: null,
    email:null,
    uid: null,
    refreshSecureToken: null,
};

const authReducer = (state=initialState, action) => {
    switch (action.type) {
        case "USER_LOADED":
        case "SIGN_IN":
        case "CREATE_USER":

        const user = jwtDecode(action.secureToken);

        toast(`Welcome to Osky, ${user.name}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
        return {
            ...initialState,
            secureToken: action.secureToken,
            name: user.name,
            email: user.email,
            uid: user.uid,
        };
        case "SIGN_OUT":
            toast("Goodbye...", {

                position: toast.POSITION.BOTTOM_RIGHT,
            });
            localStorage.removeItem("securetoken");

            return {
                secureToken: null,
                name: null,
                email: null,
                _id: null,
            };
        default:
        return state;
    }
}

export default authReducer;