import React from 'react'
const Tasks = () => {
    return ( 

        <h2>
            Tasks
        </h2>

     );
}
 
export default Tasks;