import { toast } from "react-toastify";

const noteReducer = (state=[],action) => {
    switch(action.type) {
        case 'GET_NOTES':
            return action.notes.data
        case 'ADD_NOTE':
            
            toast("Note Created", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });

        return [action.note.data, ...state]
        default:
            return state
    }
}

export default noteReducer;