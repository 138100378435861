import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { addNote } from '../../store/actions/noteActions';
import { getNotes } from '../../store/actions/noteActions';

import { url } from '../../api';
import Note from '../notes/Note';

import { makeStyles } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Typography, TextareaAutosize, TextField } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import moment from 'moment';

import { ToastContainer } from 'react-toastify';

import classNames from 'classnames';
import styles from '../assets/css/Overview.module.css';
import main from '../../App.module.css';

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '25px',
    boxShadow: 24,
    p: 4,
  };

  const useStyles = makeStyles({
    formStyle: {
        margin: '0px auto',
        marginTop: '32px',
        padding: '30px',
        borderRadius: '9px',
        boxShadow: '0px 0px 12px -3px #000000'
    },
    spacing: {
        marginTop: '20px'
    },
  });


const Overview = () => {
 
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const notes = useSelector((state) => state.notes);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotes());
  }, [dispatch])

  const [note, setNote] = useState({
    noteName: "",
    noteDescription: "",
    noteCreator: "",
    isActive: false
  })
  const handleSubmit = (e)=> {
      e.preventDefault()
      dispatch(addNote(note))
      setNote({
        noteName: "",
        noteDescription: "",
        noteCreator: "_uid458741",
        isActive: true
      })
      // handleClose();
  }

  const classes = useStyles();

  const momentDate = moment()
  const iscurrentDate = momentDate.isSame(new Date(), "day");

    return (
      <>
        <header
          className={classNames(styles.content_box1, styles.content_box1_layout)}>
          <h1 className={classNames(styles.hero_title, styles.hero_title_layout)}>
            {'Today’s Overview'}
          </h1>
          <h1 className={classNames(styles.big_title, styles.big_title_layout)}>
            {new Date().toLocaleString("en-ZA", { day: '2-digit', month: "long" })} {new Date().getFullYear()}
          </h1>
        </header>
        <main id='main' className={classNames(main.main)}>
          <div className={classNames(styles.overview, styles.block, styles.block_layout)}>
            <div className={classNames(styles.block_item1)}>
              <div id='FlexContainer' className={classNames(styles.flex, styles.flex_layout)}>

                <div className={classNames(styles.flex_overview, styles.flex_layout_overview)}>
                  <div className={classNames(styles.box_overview_1, styles.box_layout_overview)}>

                  </div>
                  <div className={classNames(styles.box_overview_2, styles.box_layout_overview)}>

                  </div>
                  <div className={classNames(styles.box_overview_3, styles.box_layout_overview)}>
                    <h1 className={classNames(styles.notes_title, styles.notes_title_layout)}>{notes.length > 0 ? "Today's Notes" : "No Notes"}</h1>
                    <div className={classNames(styles.box_list_note_layout_overview)}>
                      {notes && notes.map((note) => {

                        if( iscurrentDate === momentDate.isSame(note.date, "day") ) {
                          
                        return (
                          <Note
                            note={note}
                            key={note._id} />
                        );

                      } else {
                        return ''
                      }

                      })}
                    </div>
                    <Button onClick={handleOpen} className={styles.noteButton}>+ Add Note</Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="note-modal-title"
                      aria-describedby="note-modal-description"
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Box sx={modalStyle}>
                        <Typography id="note-modal-title" variant="h6" component="h2">
                          Add Note
                        </Typography>
                        <Typography id="note-modal-description" sx={{ mt: 2 }}>
                          Please provide your note name and description.
                        </Typography>

                        <form
                          noValidate
                          autoComplete='off'
                          className={classes.formStyle}
                          onSubmit={handleSubmit}
                          action={url + '/addNote'}
                          method="POST"
                        >
                          <TextField
                            required
                            fullWidth
                            id='noteName'
                            label='Name'
                            variant='outlined'
                            value={note.noteName}
                            className={classes.spacing}
                            onChange={(e) => setNote({ ...note, noteName: e.target.value })}
                            autoFocus />

                          <TextareaAutosize
                            aria-label="noteDescription"
                            minRows={4}
                            placeholder="Note Description"
                            style={{ width: 332 }}
                            className={classes.spacing}
                            value={note.noteDescription}
                            onChange={(e) => setNote({ ...note, noteDescription: e.target.value })}
                            autoFocus />

                          <Button
                            variant='outlined'
                            type='submit'
                            className={classes.spacing}
                          >
                            + Add Note
                          </Button>

                          <Button
                            onClick={handleClose}
                            variant='outlined'
                            className={classes.spacing}
                          >
                            Close
                          </Button>

                        </form>
                      </Box>
                    </Modal>
                  </div>
                  <div className={classNames(styles.box_overview_4, styles.box_layout_overview)}>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </main>
      </>
     );
}
 
export default Overview;