import React from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import classNames from 'classnames';
import styles from '../assets/css/PatientInformation.module.css';

<Box sx={{ width: 505 }}>
  <Skeleton />
  <Skeleton />
  <Skeleton />
</Box>

// import moment from 'moment';

const ViewPatient = ({patient}) => {

    return ( 

        <>
        <div
        className={`patient-information ${classNames(
            styles.block,
            styles.block_layout
        )}`}>
      <div className={classNames(styles.flex, styles.flex_layout)}>
        <div className={classNames(styles.flex_item)}>
        <img
            src={require('../assets/f4b33258b1911e717a950d9d9cfec958.png')}
            className={classNames(styles.image, styles.image_layout)} alt="Patient-Placeholder"
          />
        </div>
        <div className={classNames(styles.flex_spacer)} />
        {patient.length === 0 ? 
          <Box sx={{ width: 505, pt: 0.5 }} >
            <Skeleton variant="rectangular" width={505} height={118} />
            <Skeleton />
            <Skeleton width="60%" />
          </Box>
        :
          <div className={classNames(styles.flex_item1)}>
            <h2
              className={classNames(
                styles.medium_title_box,
                styles.medium_title_box_layout
              )}>
              <pre className={classNames(styles.medium_title)}>
                {
                patient.patientName + ' ' + patient.patientSurname + '\n' + patient.patientGender + '\n' + 
                moment(patient.patientBirthDate).format('YYYY/MM/DD') + '\n' + patient.patientId
                }
              </pre>
            </h2>
          </div>
        }
        <div className={classNames(styles.flex_spacer1)} />
        {patient.length === 0 ? 
          <Box sx={{ width: 505, pt: 0.5 }} >
            <Skeleton variant="rectangular" width={505} height={118} />
            <Skeleton />
            <Skeleton width="60%" />
          </Box>
        :
        <div className={classNames(styles.flex_item2)}>
          <h2
            className={classNames(
              styles.medium_title1_box,
              styles.medium_title1_box_layout
            )}>
            <pre className={classNames(styles.medium_title)}>
              {'Contact Details\n\nStreet Address\n'}
            </pre>
          </h2>
        </div>
        }
        <div className={classNames(styles.flex_spacer2)} />
        {patient.length === 0 ? 
          <Box sx={{ width: 150, pt: 0.5 }} >
            <Skeleton variant="rectangular" width={505} height={118} />
            <Skeleton />
            <Skeleton width="60%" />
          </Box>
        :
        <h2 className={classNames(styles.medium_title1, styles.medium_title1_layout)}>
          {'Insurance details'}
        </h2>
        }
      </div>
    </div>
                {/* <p>{ patient.patientName }</p>
                <p>{ patient.patientSurname }</p>
                <p>{ patient.patientGender }</p>
                <p>{ patient.patientId }</p>
                <p>{ moment(patient.patientBirthDate).format('YYYY/MM/DD') }</p>
                <p>{ patient.patientAge }</p>
                <p>{ patient.patientWeight }</p>
                <p>{ patient.patientHeight }</p>
                <p>{ patient.patientBMI }</p> */}
        </>

     );
}
 
export default ViewPatient;