import React, { useState, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { addPatientInfo } from '../../store/actions/patientsActions';
import { /*useLocation,*/ useLocation, useNavigate } from 'react-router-dom';

import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { Typography, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
// import { Container } from '@material-ui/core';
// import { MenuItem } from '@material-ui/core';
import { OutlinedInput } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { /*Autocomplete,*/ Box, IconButton, Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import MicOffIcon from '@mui/icons-material/MicOff';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import { Backdrop } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ToastContainer } from 'react-toastify';

import classNames from 'classnames';
import styles from '../assets/css/PatientConsult.module.css';
import main from '../../App.module.css';

import ViewPatient from './ViewPatient';
import { getPatient } from '../../store/actions/patientActions';

import ConsultUpload from '../upload/consultUpload';

// import { getConsults } from '../../store/actions/consultsActions';
// import { getPatient } from '../../store/actions/patientActions';


  const useStyles = makeStyles({
      formStyle: {
          padding: '30px',
          maxWidth: '1500px',
          width: '100%',
          gridArea: 'three'
      },
      spacing: {
          marginTop: '20px'
      },
      muiCustom: {
        borderRadius: '30px',
        margin: '25px',
        padding: '50px',
        boxShadow: '0px 0px 12px -3px #000000'
      },
      muiAddPatientBtn: {
        backgroundColor: '#122795', 
        color: '#fff',
        '&:hover': {
          backgroundColor: "#4caf50",
          color: '#fff'
      },
      },
      muiAddPatientBtnDisabled: {
        backgroundColor: '#808080', 
        color: '#000'
      }
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

const PatientConsults = () => {

  const classes = useStyles();

  const location = useLocation();
  const id = location.state.id;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [diagnosisDivHeight, setdiagnosisDivHeight] = useState(0);
  const [clinicalNotesDivHeight, setClinicalNotesDivHeight] = useState(0);
  const [investigationsDivHeight, setInvestigationsDivHeight] = useState(0);
  const [diagnosisManagementDivHeight, setDiagnosisManagementDivHeight] = useState(0);
  const [scriptDivHeight, setScriptDivHeight] = useState(0);

  useEffect(() => {
      dispatch(getPatient(id));
      // dispatch(getConsults(id))
    }, [dispatch, id])

  useEffect(() => {
    setdiagnosisDivHeight(document.getElementById("diagnosis").clientHeight);
    setClinicalNotesDivHeight(document.getElementById("ClinicalNotes").clientHeight);
    setInvestigationsDivHeight(document.getElementById("Investigations").clientHeight)
    setDiagnosisManagementDivHeight(document.getElementById("DiagnosisManagement").clientHeight);
    setScriptDivHeight(document.getElementById("Script").clientHeight)
  });
  
  const [ complaintDate, setComplaintDate ] = useState(false)

  const patient = useSelector((state) => state.patient);
  // const consults = useSelector((state) => state.consults);

  const [ diagnosisInputs, setDiagnosisInputs ] = useState([
    {
      diagnosis: ''
    }
  ])


  const handleComplaintInputChange = (index, event) => {
    let data = [...diagnosisInputs];
    data[index][event.target.name] = event.target.value;

    setDiagnosisInputs(data);
 }

  const addComplaintFields = () => {
      let newfield = { diagnosis: '' }
      setDiagnosisInputs([...diagnosisInputs, newfield])
  }

  // const handleKeypress = e => { 
  //   e.preventDefault();
  //   if (e.keyCode === 13) { 
  //     addComplaintFields();    
  //   }};

  const removeComplaintFields = (index) => {
    let data = [...diagnosisInputs];
    data.splice(index, 1);

    setDiagnosisInputs(data);
  }

  const [patientConsultData, setPatientConsultData] = useState({
    patientName: "",
    patientSurname: "",
    patientGender: "",
    patientCitizenship: "",
    patientId: "",
    patientBirthDate: "",
    patientHomeLanguage: "",
    patientAge: "",
    patientWeight: "",
    patientHeight: "",
    patientBMI: "",
    knownChronicIllnesses: "",
    patientMedicalScheme: ""
  })

  const handleSubmit = (e)=> {
      e.preventDefault()
      dispatch(addPatientInfo(patientConsultData))
      setPatientConsultData({
        patientName: "",
        patientSurname: "",
        patientGender: "",
        patientCitizenship: "",
        patientId: "",
        patientBirthDate: "",
        patientHomeLanguage: "",
        patientAge: "",
        patientWeight: "",
        patientHeight: "",
        patientBMI: "",
        knownChronicIllnesses: "",
        patientMedicalScheme: ""
      })
  }

  const { transcript, resetTranscript } = useSpeechRecognition();
  const [ isClinicalListening, setClinicalIsListening ] = useState(false);
  const [ isDiagnosisManagementListening, setDiagnosisManagementIsListening ] = useState(false);
  const microphoneRef = useRef(null);
  // if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
  //   return (
  //     <div className="mircophone-container">
  //       Browser does not Support Speech Recognition.
  //     </div>
  //   );
  // }

  const [ clinicalNotesTranscript, setClincalNotesTranscript ] = useState();
  const [ diagnosisManagementTranscript, setDiagnosisManagementTranscript ] = useState();

  const [ prevClinicalNotesTranscript, setPrevClincalNotesTranscript ] = useState('');
  const [ prevDiagnosisManagementTranscript, setPrevDiagnosisManagementTranscript ] = useState();

  function handleAssociativeClinicalTranscript() {
    if(prevClinicalNotesTranscript !== '') 
    { 
      setPrevClincalNotesTranscript(transcript)
    } else if (prevClinicalNotesTranscript !== '' && prevClinicalNotesTranscript !== transcript) {
      setPrevClincalNotesTranscript(prevClinicalNotesTranscript + ' ' + transcript)
      setClincalNotesTranscript(prevClinicalNotesTranscript + ' ' + transcript)
    } else if (prevClinicalNotesTranscript === '' && isDiagnosisManagementListening === true) {
      setPrevClincalNotesTranscript(transcript)
    } else {
      
    }
    
    if (isClinicalListening === true) {
       setClincalNotesTranscript(transcript)
    }
  }

  function handleAssociativeDiagnosisManageTranscript() {
    if(prevDiagnosisManagementTranscript !== '') 
    { 
      setPrevDiagnosisManagementTranscript(transcript)
    } else if (prevDiagnosisManagementTranscript !== '' && prevDiagnosisManagementTranscript !== transcript) {
      setPrevDiagnosisManagementTranscript(prevDiagnosisManagementTranscript + ' ' + transcript)
      setDiagnosisManagementTranscript(prevDiagnosisManagementTranscript + ' ' + transcript)
    } else if (prevDiagnosisManagementTranscript === '' && isDiagnosisManagementListening === true) {
      setPrevDiagnosisManagementTranscript(transcript)
    } else {

    }
    
    if (isDiagnosisManagementListening === true) {
      setDiagnosisManagementTranscript(transcript)
    }
  }

  // function handleAssociativeTranscript() {
  //   if(clinicalNotesTranscript === '') 
  //   { 
  //     isClinicalListening === true ? setClincalNotesTranscript(transcript) : setClincalNotesTranscript('');
  //   } 
    
  //   if (isDiagnosisManagementListening === true) 
  //   {
  //     const assignedTranscript = diagnosisManagementTranscript
  //     diagnosisManagementTranscript !== transcript ? setDiagnosisManagementTranscript(assignedTranscript + transcript) : setDiagnosisManagementTranscript(transcript);
  //   }
  // }

  useEffect(() => {
    handleAssociativeClinicalTranscript();
    handleAssociativeDiagnosisManageTranscript();
  })

  const handleClinicalListening = () => {
    setClinicalIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
    });
  };

  const stopClinicalHandle = (e) => {
    e.preventDefault();
    setClinicalIsListening(false);
    SpeechRecognition.stopListening();
  };

  const handleDiagnosisManageListening = () => {
    setDiagnosisManagementIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
    });
  };

  const stopDiagnosisManageHandle = (e) => {
    e.preventDefault();
    setDiagnosisManagementIsListening(false);
    SpeechRecognition.stopListening();
  };

  const handleClinicalListenReset = (e) => {
    e.preventDefault();
    resetTranscript();
  };

  const handleDiagnosisManageListenReset = (e) => {
    e.preventDefault();
    resetTranscript();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '25px',
    boxShadow: 24,
    p: 4,
  };

  const [tablValue, setTabValue] = useState(0);

  const handleTabChange = (event, newTab) => {
    setTabValue(newTab);
  };

  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };


  const pathCareLogo = require('../assets/img/pathcare-logo.png')

    // if(auth._id) return <Navigate to="/" />
    
    return (
          <>
              <header className={classNames(styles.content_box1, styles.content_box1_layout)}>
              <ViewPatient patient={patient} key={patient._id} />
              </header>
              <main id='main' className={classNames(main.main)}>
                <div id='FlexContainer' className={classNames(styles.flex, styles.flex_layout)}>
                  <div className={classNames(styles.actions_box, styles.actions_box_layout)}>
                    <Stack direction="row" spacing={2}>
                      <Button variant="contained" startIcon={<KeyboardBackspaceIcon />} onClick={() => navigate(-1)} style={{ backgroundColor: '#122795', color: '#fff', marginRight: '20px'}}>
                        Return
                      </Button>
                    </Stack>
                  </div>
        
                  <div className={classNames(styles.box_add_consult, styles.box_layout_add_consult)} >
                    <div className={classNames(styles.box_consult_grid, styles.box_layout_consult_grid)} >
                    <div className={classNames(styles.box_dates)} >
                      <div className={classNames(styles.complaintDate)}>
                        {complaintDate !== true ? 'XX/XX/XXXX' : new Date().getDate() + '/' + new Date().getMonth() + '/' + new Date().getFullYear()}
                      </div>
                    </div>
                    <div className={classNames(styles.stepsConnector)}>
                        <CircleIcon style={{ color: '#112694' }}></CircleIcon>
                      <div className={classNames(styles.stepsConnectorStep1)} style={{ height: `${diagnosisDivHeight + 100}px`}}>
                      </div>
                        <CircleIcon style={{ color: '#112694', marginTop: '-2px' }}></CircleIcon>
                      <div className={classNames(styles.stepsConnectorStep2)} style={{ height: `${clinicalNotesDivHeight + 50}px`}}>
                        
                      </div>
                        <CircleIcon style={{ color: '#112694' }}></CircleIcon>
                      <div className={classNames(styles.stepsConnectorStep3)} style={{ height: `${investigationsDivHeight + 50}px`}}>
                        
                      </div>
                        <CircleIcon style={{ color: '#112694' }}></CircleIcon>
                      <div className={classNames(styles.stepsConnectorStep4)} style={{ height: `${diagnosisManagementDivHeight + 50}px`}}>
                        
                      </div>
                        <CircleIcon style={{ color: '#112694' }}></CircleIcon>
                      <div className={classNames(styles.stepsConnectorStep5)} style={{ height: `${scriptDivHeight + 50}px`}}>
                        
                      </div>
                        <CircleIcon style={{ color: '#112694' }}></CircleIcon>
                    </div>
                    <form
                      noValidate
                      autoComplete='off'
                      className={ classes.formStyle }
                      onSubmit={handleSubmit}
                      // onSubmit={calculate}
                      >
                    <div className={classNames(styles.box_stepper)} >
                      <Box sx={{ maxWidth: 800 }} style={{ width: '800px'}}>
                        <Typography variant='h4'>New Patient Consultation</Typography>
                        <div id='diagnosis'>
                          {
                          diagnosisInputs.map((input, index) => {
                            return (
                              
                                <FormControl key={index} sx={{ m: 20 }} variant="outlined" style={{width: '100%'}}>
                                <FormHelperText id="patientComplaint-helper-text">Diagnosis:</FormHelperText>
                                  <OutlinedInput
                                    fullWidth
                                    key={index}
                                    value={(input.diagnosis)}
                                    name='diagnosis'
                                    placeholder={`Specify Diagnosis ${index + 1}`}
                                    id='patientComplaint'
                                    className={classNames(styles.complaintInput, classes.spacing)}
                                    ref={microphoneRef}
                                    endAdornment={
                                      index !== 0 ? 
                                      <InputAdornment key={index} position="end">
                                        <Stack key={index} direction="row" spacing={2} >

                                      {/* {!SpeechRecognition.browserSupportsSpeechRecognition() ? (
                                            <IconButton>
                                              <StopCircleIcon style={{ color : 'grey'}} />
                                            </IconButton>
                                        )
                                          : 
                                          isListening ? 
                                            <IconButton onClick={stopHandle} aria-label='Recognize Complaint'>
                                              <StopCircleIcon style={{ color : 'red'}} />
                                            </IconButton>
                                            : 
                                            <IconButton onClick={handleListing} aria-label='Recognize Complaint'>
                                              <KeyboardVoiceIcon style={{ color : 'green'}} />
                                            </IconButton>
                                          
                                        } */}

                                          <IconButton onClick={addComplaintFields} aria-label='Add Complaint'>
                                            <AddCircleIcon style={{ color : '#122795'}} />
                                          </IconButton>
                      
                                          <IconButton onClick={()=> removeComplaintFields(index)} aria-label='Remove Complaint'>
                                            <RemoveCircleIcon style={{ color : 'red'}} />
                                          </IconButton>
                                        </Stack>
                                      </InputAdornment>
                                      : 
                                      <InputAdornment position="end">

                                      {/* {!SpeechRecognition.browserSupportsSpeechRecognition() ? (
                                            <IconButton>
                                              <StopCircleIcon style={{ color : 'grey'}} />
                                            </IconButton>
                                        )
                                          : 
                                          isListening ? 
                                            <IconButton onClick={stopHandle} aria-label='Recognize Complaint'>
                                              <StopCircleIcon style={{ color : 'red'}} />
                                            </IconButton>
                                            : 
                                            <IconButton onClick={handleListing} aria-label='Recognize Complaint'>
                                              <KeyboardVoiceIcon style={{ color : 'green'}} />
                                            </IconButton>
                                          
                                        } */}

                                        <IconButton onClick={addComplaintFields} aria-label='Add Complaint'>
                                          <AddCircleIcon style={{ color : '#122795'}} />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    onChange={event => handleComplaintInputChange(index, event)}
                                    onInput={index === 0 ? ()=> setComplaintDate(true) : null}
                                    // onKeyPress={handleKeypress}
                                    aria-describedby="patientComplaint-helper-text"
                                    inputProps={{
                                    'aria-label': 'diagnosis',
                                    }}
                                  />
                                </FormControl>
                            )
                            })
                            
                          }
                        </div>

                        <div id='ClinicalNotes' className={classNames(styles.ClinicalNotes)}>
                        <Typography variant='h5'>Clinical Notes:</Typography>
                          <Stack direction="row" spacing={2} >
                            <TextField
                              value={clinicalNotesTranscript}
                              placeholder={isClinicalListening ? "Listening........." : ''}
                              multiline
                              minRows={4}
                              ref={microphoneRef}
                              style={{ width: '760px'}}
                            />

                            {!SpeechRecognition.browserSupportsSpeechRecognition() ? (
                                <Tooltip title="Speech to Text - Not Supported by your Browser">
                                  <IconButton className={classNames(styles.notBrowserSupported)}>
                                    <MicOffIcon style={{ color : 'grey'}} />
                                  </IconButton>
                                </Tooltip>
                            )
                              : 
                              isClinicalListening ? 
                                <Tooltip title="Stop Recording">
                                  <IconButton onClick={(e) => { stopClinicalHandle(e); handleClinicalListenReset(e); }} aria-label='Recognize Complaint'>
                                    <StopCircleIcon style={{ color : 'red'}} />
                                  </IconButton>
                                </Tooltip>
                                : 
                                <Tooltip title="Speech to Text">
                                  <IconButton onClick={handleClinicalListening} aria-label='Recognize Complaint'>
                                    <KeyboardVoiceIcon style={{ color : 'green'}} />
                                  </IconButton>
                                </Tooltip>
                              }
                          </Stack>

                        </div>

                        <div id='Investigations' className={classNames(styles.Investigations)}>
                          <Typography variant='h5'>Investigations:</Typography>

                          <Stack direction="row" spacing={4} style={{ margin: '25px'}}>
                            <Button variant="contained" onClick={handleOpen} aria-label='Labs' style={{ backgroundColor: 'rgb(18, 39, 149)', color: 'rgb(255, 255, 255)'}}>
                              Labs
                            </Button>
                            <Button variant="contained" onClick={handleOpen} aria-label='Imaging' style={{ backgroundColor: 'rgb(18, 39, 149)', color: 'rgb(255, 255, 255)'}}>
                              Imaging
                            </Button>
                          </Stack>

                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="labs-modal-title"
                            aria-describedby="labs-modal-description"
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                          <div className={classNames(styles.labsModal, styles.labsModalStyle)}>
                            <IconButton className={classNames(styles.modalCloseBtn)} onClick={handleClose}><CloseIcon /></IconButton>
                                              {/* <Typography id="booking-modal-title" variant="h6" component="h2">
                                                Schedule Appointment
                                              </Typography>
                                              <Typography id="booking-modal-description" sx={{ mt: 2 }}>
                                                Pleas provide Booking Details
                                              </Typography> */}
                            <div className={classNames(styles.labsModalAreaOne)}>
                              <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={tablValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                className={classNames(styles.labsVerticalTabsLeft)}
                              >
                                <Tab label="PathCare" {...a11yProps(0)} />
                                <Tab label="Ampath" {...a11yProps(1)} />
                                <Tab label="Lancet" {...a11yProps(2)} />
                              </Tabs>
                            </div>
                            <div className={classNames(styles.labsModalAreaTwo)}>
                              <TabPanel value={tablValue} index={0}>
                                { !pathCareLogo ? 'PathCare' : ( <img src={require('../assets/img/pathcare-logo.png')} alt='PathCare'></img> ) }

                                <Accordion expanded={accordionExpanded === 'panelGeneral'} onChange={handleAccordionChange('panelGeneral')}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panelGeneral-content"
                                    id="panelGeneral-header"
                                    className={classNames(styles.panelGeneral)}
                                  >
                                    <Typography style={{ width: '50%', flexShrink: 0, marginLeft: '15px' }}>Lab Request</Typography>
                                    <Typography style={{ color: '#122795', fontWeight: 700 }}>General</Typography>
                                  </AccordionSummary>
                                    <AccordionDetails>
                                      <Stack direction="row" spacing={4} style={{ margin: '25px'}}>
                                        <Button variant='contained' style={{ backgroundColor: '#122795', color: '#fff'}}>
                                          English
                                        </Button>
                                        <Button variant='contained' style={{ backgroundColor: '#122795', color: '#fff'}}>
                                          Afrikaans
                                        </Button>
                                      </Stack>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={accordionExpanded === 'panelGynae'} onChange={handleAccordionChange('panelGynae')}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panelGynae-content"
                                    id="panelGynae-header"
                                    className={classNames(styles.panelGynae)}
                                  >
                                    <Typography style={{ width: '50%', flexShrink: 0, marginLeft: '15px' }}>Lab Request</Typography>
                                    <Typography style={{ color: '#122795', fontWeight: 700 }}>Gynae</Typography>
                                  </AccordionSummary>
                                    <AccordionDetails>
                                      <Stack direction="row" spacing={4} style={{ margin: '25px'}}>
                                        <Button variant='contained' style={{ backgroundColor: '#122795', color: '#fff'}}>
                                          English
                                        </Button>
                                        <Button variant='contained' style={{ backgroundColor: '#122795', color: '#fff'}}>
                                          Afrikaans
                                        </Button>
                                      </Stack>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={accordionExpanded === 'panelCovid'} onChange={handleAccordionChange('panelCovid')}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panelCovid-content"
                                    id="panelCovid-header"
                                    className={classNames(styles.panelCovid)}
                                  >
                                    <Typography style={{ width: '50%', flexShrink: 0, marginLeft: '15px' }}>Lab Request</Typography>
                                    <Typography style={{ color: '#122795', fontWeight: 700 }}>Covid</Typography>
                                  </AccordionSummary>
                                    <AccordionDetails>
                                      <Stack direction="row" spacing={4} style={{ margin: '25px'}}>
                                        <Button variant='contained' style={{ backgroundColor: '#122795', color: '#fff'}}>
                                          Line of Contact
                                        </Button>
                                        <Button variant='contained' style={{ backgroundColor: '#122795', color: '#fff'}}>
                                          Request Form
                                        </Button>
                                      </Stack>
                                    </AccordionDetails>
                                </Accordion>
                                
                              </TabPanel>
                              <TabPanel value={tablValue} index={1}>
                                Ampath
                              </TabPanel>
                              <TabPanel value={tablValue} index={2}>
                                Lancet
                              </TabPanel>
                            </div>
                          </div>
                          </Modal>
                          {/* <ConsultUpload /> */}
                        </div>
                        <div id='DiagnosisManagement' className={classNames(styles.DiagnosisManagement)}>
                          <Typography variant='h5'>Diagnosis &amp; Management:</Typography>

                          <Stack direction="row" spacing={2} >
                            <TextField
                              value={diagnosisManagementTranscript}
                              placeholder={isDiagnosisManagementListening ? "Listening........." : ''}
                              multiline
                              minRows={4}
                              ref={microphoneRef}
                              style={{ width: '760px'}}
                            />

                            {!SpeechRecognition.browserSupportsSpeechRecognition() ? (
                                <Tooltip title="Speech to Text - Not Supported by your Browser">
                                  <IconButton className={classNames(styles.notBrowserSupported)}>
                                    <MicOffIcon style={{ color : 'grey'}} />
                                  </IconButton>
                                </Tooltip>
                            )
                              : 
                              isDiagnosisManagementListening ? 
                                <Tooltip title="Stop Recording">
                                  <IconButton onClick={(e)=> { stopDiagnosisManageHandle(e); handleDiagnosisManageListenReset(e); }} aria-label='Recognize Diagnosis'>
                                    <StopCircleIcon style={{ color : 'red'}} />
                                  </IconButton>
                                </Tooltip>
                                : 
                                <Tooltip title="Speech to Text">
                                  <IconButton onClick={handleDiagnosisManageListening} aria-label='Recognize Diagnosis'>
                                    <KeyboardVoiceIcon style={{ color : 'green'}} />
                                  </IconButton>
                                </Tooltip>
                              }
                          </Stack>
                        </div>
                        <div id='Script' className={classNames(styles.Script)}>
                          <Typography variant='h5'>Script:</Typography>
                        </div>
                      </Box>
                    </div>
                    </form>
                    </div>
                  </div>
                  <div className={classNames(styles.box_prev_consults, styles.box_layout_prev_consults)} >
                  </div>
                </div>    
              </main>

              <ToastContainer />
            </>
        
      );
}
 
export default PatientConsults;