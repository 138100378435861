import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getPatient } from '../../store/actions/patientActions';

// import { Typography, Button, TextField } from '@material-ui/core';
import { makeStyles, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton, Stack } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
// import { Container } from '@material-ui/core';
// import { MenuItem } from '@material-ui/core';
// import { OutlinedInput } from '@material-ui/core';
// import { InputAdornment } from '@material-ui/core';
// import { FormControl } from '@material-ui/core';
// import { FormHelperText } from '@material-ui/core';
// import { Grid } from '@material-ui/core';
// import { capitalize } from '@material-ui/core';

import { useLocation, useNavigate } from "react-router-dom"

import { ToastContainer } from 'react-toastify';

import classNames from 'classnames';
import styles from '../assets/css/AddPatient.module.css';
import main from '../../App.module.css';
import ViewPatient from './ViewPatient';

const useStyles = makeStyles({
    formStyle: {
        margin: '0px auto',
        padding: '30px',
        borderRadius: '9px',
        boxShadow: '0px 0px 12px -3px #000000'
    },
    spacing: {
        marginTop: '20px'
    },
    spacingBottom: {
        marginBottom: '20px',
        marginLeft: '460px'
    },
    muiCustom: {
      borderRadius: '30px',
      marginTop: '24px'
    }
});

const Patient = () => {

const location = useLocation();
const id = location.state.id;

const sendPatientDataId = (patiendtObjectId)=>{
  navigate('/consultation',{
      state:{ id:patiendtObjectId }});
  }


const navigate = useNavigate();
const dispatch = useDispatch();

useEffect(() => {
    dispatch(getPatient(id))
  }, [])

const patient = useSelector((state) => state.patient);

const classes = useStyles();

    // const auth = useSelector(state=> state.auth)
  

    // if(auth._id) return <Navigate to="/" />
    
    return (

      <>

          <header className={classNames(styles.content_box1, styles.content_box1_layout)}>
          <ViewPatient patient={patient} key={patient._id} />
          </header>

            <main id='main' className={classNames(main.main)}>
            <div className={classNames(styles.flex, styles.flex_layout)}>
              <div className={classNames(styles.actions_box, styles.actions_box_layout)}>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" startIcon={<KeyboardBackspaceIcon />} onClick={() => navigate(-1)} style={{ backgroundColor: '#122795', color: '#fff', marginRight: '20px'}}>
                    Return
                  </Button>
                </Stack>
              </div>
              <div className={classNames(styles.box_patient, styles.box_layout_patient)} >

                  <Typography variant='h4' className={classes.spacingBottom}>Summary</Typography>
                <div className={classNames(styles.box_patient_grid, styles.box_layout_patient_grid)} >
                    <div className={classNames(styles.box_complaints, styles.box_layout_overview)} >

                    </div>
                    <div className={classNames(styles.box_visits, styles.box_layout_overview)} >

                    </div>
                    <div className={classNames(styles.box_history, styles.box_layout_overview)} >

                    </div>
                    <div className={classNames(styles.box_records, styles.box_layout_overview)} >
                      <Stack direction="row" spacing={4}>
                        <div className={classNames(styles.startConsult)} onClick={()=>{sendPatientDataId(patient._id)}}>
                          <Typography className={styles.consultation_heading}>Consultation</Typography>
                          
                          <IconButton onClick={()=>{sendPatientDataId(patient._id)}} aria-label='Add Records'>
                            <AddCircleOutlineIcon style={{ color : '#fff', fontSize: 64, marginTop: '-100px'}}/>
                          </IconButton>
                        </div>
                        <span style={{ height: '275px', borderLeftStyle: 'solid', borderLeftWidth: '2px', borderLeftColor: '#fff'}}></span>
                        <div className={classNames(styles.medRecords)}>
                          <Typography className={styles.medicalRecords_heading}>Medical Records</Typography>
                          
                          {/* <IconButton aria-label='View Records'>
                            <VisibilityTwoToneIcon style={{ color : '#fff', fontSize: 64, marginTop: '0px'}}/>
                          </IconButton> */}
                        </div>
                    </Stack>
                    </div>
                </div>
              </div>     
            </div>  
        <ToastContainer />
        
        </main>
        </>
      );
}
 
export default Patient;