import React from 'react';

import { /*makeStyles,*/ Typography } from '@material-ui/core';
import styles from '../assets/css/Overview.module.css'
import moment from 'moment';

// const useStyles = makeStyles({
//     grayColor:{
//         color: "#333"
//     },
//     isActive:{
//         color: "green"
//     },
//     checked:{
//         textDecoration: "line-through"
//     }
// })

const Note = ({note}) => {

    // const classes = useStyles()
    return ( 

        <>
            <div className={styles.noteStyle}>
                <div>
                    <Typography variant='subtitle1'>
                        { note.noteName }
                    </Typography>
                    <Typography variant='subtitle1'>
                        { note.noteDescription }
                    </Typography>
                    <Typography variant='subtitle1'>
                        Added: { moment(note.date).fromNow() }
                    </Typography>
                </div>
            </div>
        </>

     );
}
 
export default Note;