import React, { useEffect } from 'react';

/*
 * Redux Imports
 */

import { useDispatch, useSelector } from 'react-redux';

/*
 * React Router Imports
 */

import {
  Routes,
  Route,
} from "react-router-dom";

/*
 * Component Imports
 */

import SignIn from './components/auth/Signin';
import RegisterUser from './components/auth/Register';
import Tasks from './components/tasks/Tasks';
import AddPatient from './components/patients/AddPatient';
import Patients from './components/patients/Patients';
import Patient from './components/patients/Patient';
import { loadUser, signOut } from './store/actions/authActions';
import Overview from './components/dashboard/Overview';
import PatientsOverview from './components/patients/OverviewPatient';
import PatientConsults from './components/patients/PatientConsult'
import UpdatePatient from './components/patients/UpdatePatient';
import SideBar from './components/sidebar/sideBar';
import Calendar from './components/calendar/Calendar';

/*
 * APP CSS Import
 */

import './App.css';


/*
 * Toast Imports
 */

import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"



function App() {

  const auth = useSelector(state=> state.auth)

  const dispatch = useDispatch()

  useEffect(()=> {
    dispatch(loadUser())
  }, [dispatch])

  const handleSignOut = () => {
    dispatch(signOut());
  };

  if (!auth.uid) return <SignIn />;


  // if(auth.uid === null) return <Navigate to="/signin" />;

  return (

    <>
          <Routes>
              <Route index element={<Overview />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/" element={<Overview />} />
              <Route path="/register" element={<RegisterUser />} />
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/addPatient" element={<AddPatient />} />
              <Route path="/patients" element={<Patients />} />
              <Route path="/patient" element={<Patient />} />
              <Route path="/updatePatient" element={<UpdatePatient />} />
              <Route path="/patientOverview" element={<PatientsOverview />} />
              <Route path="/consultation" element={<PatientConsults />} />
              <Route path="/calendar" element={<Calendar />} />
          </Routes>

          <SideBar handleSignOut={handleSignOut}/>

    <ToastContainer />
    </>
  );
}

export default App;
