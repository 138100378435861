import axios from 'axios';
import { url, setHeaders } from '../../api';
import { toast } from 'react-toastify';

export const getNotes = ()=> {
    return (dispatch, getState)=> {
        axios
        .get(`${url}/getNotes`, setHeaders())
        .then(notes=> {

            dispatch({
                type: 'GET_NOTES',
                notes
            })
        })
        .catch(error=> {
            console.log(error.response)

            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }
}

export const addNote = (note)=> {
    return (dispatch, getState)=> {

        const noteCreator = getState().auth.noteCreator
        const uid = getState().auth.uid

        axios
        .post(`${url}/addNote`, {...note, noteCreator, uid}, setHeaders())
        .then(note=> {

            dispatch({
                type: 'ADD_NOTE',
                note
            })
        })
        .catch(error=> {
            console.log(error.response)

            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }
}

export const updateNote = (updatedNote, id)=> {
    return (dispatch)=> {
        axios
        .put(`${url}/updateNotes/${id}`, updatedNote, setHeaders())
        .then(note=> {

            dispatch({
                type: 'UPDATE_NOTE',
                note
            })
        })
        .catch(error=> {
            console.log(error.response)

            toast.error(error.response?.data, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }
}

// return (dispatch, getState)=> {
//     axios
//     .post(`${url}/addPatient`, patientData)
//     .then(securetoken=> {
//         localStorage.setItem('securetoken', securetoken.data)

//         dispatch({
//             type: 'ADD_PATIENT',
//             securetoken: securetoken.data
//         })
//     })
//     .catch(error=> {
//         console.log(error.response)

//         toast.error(error.response?.data, {
//             position: toast.POSITION.BOTTOM_RIGHT
//         });
//     })
// }
// }

// export const createUser = (user)=> {
//     return (dispatch)=> {
//         axios
//         .post(`${url}/createUser`, user)
//         .then(securetoken=> {
//             localStorage.setItem('securetoken', securetoken.data)

//             dispatch({
//                 type: 'CREATE_USER',
//                 securetoken: securetoken.data
//             })
//         })
//         .catch(error=> {
//             console.log(error.response)

//             // toast.error(error.response?.data) {
//             //     position: toast.POSITION.BOTTOM_RIGHT
//             // }
//         })
//     }
// }

export const loadUser = ()=> {
    return (dispatch, getState)=> {

        const secureToken = getState().auth.secureToken

        if(secureToken){
            dispatch({
                type: "USER_LOADED",
                secureToken
            })
        } else return null
    }
}