import React, { useEffect, useState } from 'react';

// import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import { getAppointments } from '../../store/actions/appointmentActions';
// import { searchPatients } from '../../store/actions/patientsActions';

// import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { capitalize, InputAdornment, Stack } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PeopleIcon from '@mui/icons-material/People';
import { TextField, /*TextareaAutosize,*/ Typography } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Backdrop } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
// import { LinearProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';

import main from '../../App.module.css';

import classNames from 'classnames';
import styles from '../assets/css/OverviewPatients.module.css';
import { url, setHeaders } from '../../api';
import axios from 'axios';


// import ListAppointments from './ListAppointments';

  const useStyles = makeStyles({
      muiCustomTable: {
          borderTopLeftRadius: '30px',
          borderTopRightRadius: '30px',
      },
      muiCustomTableText: {
          fontWeight: '700',
          color: '#122795'
      }
  });

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '25px',
    boxShadow: 24,
    p: 4,
  };


const PatientsOverview = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [startTime, setStartTime] = useState(new Date());
    const [expectedEndTime, setExpectedEndTime] = useState(new Date());

    // const patient = useSelector((state) => state.patient);

    // const [searchQuery, setSearchQuery] = useState("");
    // const [booking, setBooking] = useState({
    //   practitioner: "",
    //   staffCreated: "",
    //   puid: "62704b0b964931f3509c2d2a", 
    //   uid: "",
    //   patientName: "",
    //   patientContact: "",
    //   startTime: startTime,
    //   expectedEndTime: expectedEndTime
    // })

    // const [loadingState, setLoadingState] = useState(0)

    const [searchQuery, setQuery] = useState("")
    const [patientResults, setPatient] = useState([])

  
    useEffect(() => {
      
        const fetchResults = async () => {
          if (searchQuery !== '') {
          try {
              const res = await axios.get(`${url}${'/searchPatients/' + searchQuery}`, setHeaders());
              if(res.status === 200) {
                setPatient(res.data);
              }
              // 
              // console.log(res)
          } catch (error) {
              // console.error(error)
          }
      };
    }
    fetchResults();
    }, [searchQuery])

    const clearSearch = () => {
      setPatient("");
      setQuery("");
    }

    // const dispatch = useDispatch();

    // useEffect(() => {
    // // dispatch(getAppointments())
    // dispatch(searchPatients(searchQuery))
    // }, [])

    const navigate = useNavigate();

    const navigateTo = (path)=>{
        navigate(path);
        }

    const sendPatientDataId = (patiendtObjectId)=>{
      navigate('/patient',{
          state:{ id:patiendtObjectId }});
      }

    // const sendPatientDataIdUpdate = (patiendtObjectId)=>{
    //     navigate('/updatePatient',{
    //         state:{ id:patiendtObjectId }});
    //     }

    const classes = useStyles();


    // if(auth._id) return <Navigate to="/" />
    
    return (
      <>
              <header
                className={classNames(styles.content_box1, styles.content_box1_layout)}>
                <h1 className={classNames(styles.hero_title, styles.hero_title_layout)}>
                  {'Patients'}
                </h1>
                <h1 className={classNames(styles.big_title, styles.big_title_layout)}>
                {new Date().toLocaleString("en-ZA", { day : '2-digit', month: "long" } )} {new Date().getFullYear()}
                </h1>
              </header>
              <main id='main' className={classNames(main.main)}>
                <div id='FlexContainer' className={classNames(styles.flex, styles.flex_layout)}>
                  <div className={classNames(styles.actions_box, styles.actions_box_layout)}>
                    <Stack direction="row" spacing={2} style={{marginRight: '20px'}}>

                          <TextField
                            id="search-bar"
                            onChange={event => setQuery(event.target.value)}
                            value={capitalize(searchQuery)}
                            variant="outlined"
                            placeholder="Search Patients..."
                            size="small"
                            style={{ marginLeft: '70px', width: '300px'}}
                            InputProps={searchQuery.length > 0 ? {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon style={{ fill: "#122795" }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={()=> clearSearch()} style={{ marginRight: "-20px" }}>
                                    <CloseIcon style={{ fill: "#122795" }} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            } : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon style={{ fill: "#122795" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <div className={searchQuery.length > 0 ? classNames(styles.searchBoxShown) : classNames(styles.searchBoxHidden)}>
                            {  patientResults.length === 0 && searchQuery !== '' ? 
                                    <ul className={classNames(styles.searchDropdown)}>
                                      <li key='NoneFound' className={classNames(styles.searchResult)}>
                                        No Results Found
                                      </li>
                                    </ul> : 
                              <ul className={classNames(styles.searchDropdown)}>
                               {patientResults && patientResults.map((patientResult) => {
                                  return (
                                    <>
                                    
                                      <li key={patientResult._id} className={classNames(styles.searchResult)}>
                                      <Stack key={patientResult._id} direction="row" spacing={2} sx={{alignItems: 'center', width: '256px'}}>  
                                        <PersonIcon sx={{ fill: '#122795', marginRight: '10px' }}/>
                                        {patientResult.patientName + ' ' + patientResult.patientSurname}
                                        <IconButton key={patientResult._id} aria-label="View" onClick={()=>{sendPatientDataId(patientResult._id)}}>
                                            <VisibilityTwoToneIcon key={patientResult._id} sx={{ fill: '#122795'}}/>
                                        </IconButton>
                                      </Stack>
                                      </li>
                                    
                                    </>
                                    )
                                })
                              }
                              </ul>
                            }
                          </div>
                        <Button variant="contained" endIcon={<DateRangeIcon />} onClick={handleOpen} style={{ backgroundColor: '#000', color: '#fff'}}>
                            Add Appointment
                        </Button>
                        <Button variant="contained" startIcon={<PeopleIcon />} onClick={()=>navigateTo('/patients')} style={{ backgroundColor: '#fff'}}>
                            Patients
                        </Button>
                        <Button variant="outlined" endIcon={<PersonAddAltIcon />} onClick={()=>navigateTo('/addPatient')} style={{ backgroundColor: '#122795', color: '#fff'}}>
                            Add Patient
                        </Button>
                    </Stack>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="booking-modal-title"
                        aria-describedby="booking-modal-description"
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Box sx={modalStyle}>
                          <Typography id="booking-modal-title" variant="h6" component="h2">
                            Schedule Appointment
                          </Typography>
                          <Typography id="booking-modal-description" sx={{ mt: 2 }}>
                            Pleas provide Booking Details
                          </Typography>

                          <form
                            noValidate
                            autoComplete='off'
                            action={url + '/ScheduleAppointment'}
                            method="POST"
                          >
                          {/* <MobileDateTimePicker
                            value={startTime}
                            label="Start Time"
                            onChange={(newBookingDate) => {
                              setStartTime(newBookingDate);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          /> */}
                            <div className={classNames(styles.booking_grid)}>
                                <div className={classNames(styles.row_appointment_times)}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack direction="row" spacing={4} justifyContent='center'>
                                        <DesktopDateTimePicker
                                          value={startTime}
                                          label="Start"
                                          onChange={(newBookingDateStart) => {
                                            setStartTime(newBookingDateStart);
                                          }}
                                          renderInput={(params) => <TextField {...params} />}
                                        />
                                        <DesktopDateTimePicker
                                          value={expectedEndTime}
                                          label="End"
                                          onChange={(newBookingDateEnd) => {
                                            setExpectedEndTime(newBookingDateEnd);
                                          }}
                                          renderInput={(params) => <TextField {...params} />}
                                        />
                                        </Stack>
                                    </LocalizationProvider>
                                </div>
                                <div className={classNames(styles.row_appointment_details)}>

                                </div>
                                <div className={classNames(styles.row_appointment_details2)}>

                                </div>
                                <div className={classNames(styles.row_appointment_buttons)}>
                                <Stack direction="row" spacing={2} style={{marginRight: '20px'}} justifyContent='flex-end'>
                                  <Button 
                                    variant='outlined'
                                    type='submit'
                                    className={classes.spacing}
                                  >
                                  Book
                                  </Button>

                                  <Button
                                    onClick={handleClose}
                                    variant='outlined'
                                    className={classes.spacing}
                                  >
                                  Close
                                  </Button>
                                </Stack>
                                </div>
                            </div>
                          </form>
                        </Box>
                    </Modal>
                  </div>
        
                  <div className={classNames(styles.box_patients, styles.box_layout_overview_patients)} >
                    <div className={classNames(styles.box_patients_overview, styles.box_layout_patients_overview)} >
                    </div>
                  </div>
                </div>      
              </main>
      </>
      );
}
 
export default PatientsOverview;
