import { toast } from "react-toastify";

const patientReducer = (state=[],action) => {
    switch(action.type) {
        case 'GET_PATIENT':
            return action.patient.data
        case "UPDATE_PATIENT":
            toast.success("Patient information was updated...", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return state.map((patient) =>
            patient._id === action.patient.data._id ? action.patient.data : patient
            );   
        default:
            return state
    }
}

export default patientReducer;