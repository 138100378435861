import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getPatient } from '../../store/actions/patientActions';
import PatientUpdate from './PatientUpdate';

const UpdatePatient = () => {

  const location = useLocation();
  const id = location.state.id;

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getPatient(id))
    }, [])

  const patient = useSelector((state) => state.patient);


    // if(auth._id) return <Navigate to="/" />
    
    return (
        <PatientUpdate patient={patient} key={patient._id} />
      );
}
 
export default UpdatePatient;