const buildMonthlyCalendar = (calComp) => {

    const startDay = calComp.clone().startOf('M').startOf('W');
    const endDay = calComp.clone().endOf('M').endOf('W');

    const day = startDay.clone().subtract(1, "day");
    const calendarWhile = [];

        while (day.isBefore(endDay, "day")) {
            calendarWhile.push(
                Array(7).fill(0).map(()=> day.add(1, "day").clone())
            );
        }
        return calendarWhile
    }

export default buildMonthlyCalendar;