import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPatients } from '../../store/actions/patientsActions';

import { makeStyles } from '@material-ui/core';
// import { TableContainer, Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
// import { Paper } from '@material-ui/core';
import { Button } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EditIcon from '@mui/icons-material/Edit';

import classNames from 'classnames';
import main from '../../App.module.css';
import styles from '../assets/css/ListPatients.module.css';

// import ListPatients from './ListPatients';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'patientName',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'patientSurname',
    numeric: false,
    disablePadding: false,
    label: 'Surname',
  },
  {
    id: 'patientGender',
    numeric: true,
    disablePadding: false,
    label: 'Gender',
  },
  {
    id: 'patientId',
    numeric: true,
    disablePadding: false,
    label: 'ID Number',
  },
  {
    id: 'tableActions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all patients',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Patients
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    muiCustomTable: {
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
    },
    muiCustomTableText: {
        fontWeight: '700',
        color: '#122795'
    }
});

const Patients = () => {

  const patients = useSelector((state) => state.patients);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatients())
  }, [])

  const classes = useStyles();

  const navigate = useNavigate();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = patients.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - patients.length) : 0;

  const sendPatientDataId = (patiendtObjectId)=>{
      navigate('/patient',{
          state:{ id:patiendtObjectId }});
      }

  const sendPatientDataIdUpdate = (patiendtObjectId)=>{
      navigate('/updatePatient',{
          state:{ id:patiendtObjectId }});
      }


    // if(auth._id) return <Navigate to="/" />
    
    return (
      
            <>
              <header
                className={classNames(styles.content_box1, styles.content_box1_layout)}>
                <h1 className={classNames(styles.hero_title, styles.hero_title_layout)}>
                  {'Patients'}
                </h1>
                <h1 className={classNames(styles.big_title, styles.big_title_layout)}>
                {new Date().toLocaleString("en-ZA", { day : '2-digit', month: "long" } )} {new Date().getFullYear()}
                </h1>
              </header>


              <main id='main' className={classNames(main.main)}>
              <div id="FlexContainer" className={classNames(styles.flex, styles.flex_layout)}>
                <div className={classNames(styles.actions_box, styles.actions_box_layout)}>
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" startIcon={<KeyboardBackspaceIcon />} onClick={() => navigate(-1)} style={{ backgroundColor: '#122795', color: '#fff', marginRight: '20px'}}>
                      Return
                    </Button>
                  </Stack>
                </div>
      
                <div className={classNames(styles.box_patient, styles.box_layout_patient)} >
                  <div className={classNames(styles.box_list_patients, styles.box_layout_list_patients)} >
                      {/* <TableContainer component={Paper} className={classes.muiCustomTable}>
                          <Table sx={{ minWidth: '70.5%' }} aria-label="Patients" className={classes.muiCustomTable}>
                          <TableHead>
                              <TableRow>
                              <TableCell className={classes.muiCustomTableText}>Name</TableCell>
                              <TableCell className={classes.muiCustomTableText}>Surname</TableCell>
                              <TableCell className={classes.muiCustomTableText}>Gender</TableCell>
                              <TableCell className={classes.muiCustomTableText}>ID</TableCell>
                              <TableCell className={classes.muiCustomTableText}>Actions</TableCell>
                              </TableRow>
                          </TableHead>
                              <TableBody>
                              { patients && patients.map((patient) => {
                                  return (
                                  <ListPatients 
                                      patient={patient}
                                      key={patient._id}
                                  />
                                  )
                              })}
                              </TableBody>
                          </Table>
                      </TableContainer> */}

                    <Box sx={{ width: 'inherit' }}>
                      <Paper sx={{ width: '100%', mb: 2 }} className={classes.muiCustomTable}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                          >
                            <EnhancedTableHead
                              numSelected={selected.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={patients.length}
                            />
                            <TableBody>
                              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                rows.slice().sort(getComparator(order, orderBy)) */}
                              {stableSort(patients, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((patient, index) => {
                                  const isItemSelected = isSelected(patient.name);
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <TableRow
                                      hover
                                      onClick={(event) => handleClick(event, patient.name)}
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={patient._id}
                                      selected={isItemSelected}
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell align="left">{patient.patientName}</TableCell>
                                      <TableCell align="left">{patient.patientSurname}</TableCell>
                                      <TableCell align="right">{patient.patientGender}</TableCell>
                                      <TableCell align="right">{patient.patientId}</TableCell>
                                      <TableCell>
                                      <Stack direction="row" spacing={1}>    
                                          <IconButton aria-label="View" onClick={()=>{sendPatientDataId(patient._id)}}>
                                              <VisibilityTwoToneIcon />
                                          </IconButton>
                                          <IconButton aria-label="View" onClick={()=>{sendPatientDataIdUpdate(patient._id)}}>
                                              <EditIcon />
                                          </IconButton>
                                      </Stack>
                                    </TableCell>
                                    </TableRow>
                                  );
                                })}
                              {emptyRows > 0 && (
                                <TableRow
                                  style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                  }}
                                >
                                  <TableCell colSpan={6} />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          component="div"
                          count={patients.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                      <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                        label="Dense padding"
                      />
                    </Box>
                  </div>
                </div> 
              </div>       
              </main>
            </>
      );
}
 
export default Patients;
